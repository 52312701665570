body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Trebuchet MS', 'Verdana', 'Arial', 'sans-serif' !important;
  src: url(/static/media/TrebuchetMS.8f308fe7.ttf) format("truetype") !important; }

@font-face {
  font-family: "Neuropol X";
  src: url(/static/media/neuropolX.e42ada49.ttf) format("truetype") !important; }

html body {
  overflow-x: hidden !important;
  font-family: 'Trebuchet MS', 'Verdana', 'Arial', 'sans-serif' !important;
  background: #222222; }
  html body .container {
    background: #F5F5F5; }

.alert-danger {
  margin-top: 30px; }

.linija .asd {
  position: relative;
  padding: unset !important; }
  .linija .asd:after {
    content: '';
    height: 90%;
    width: 20px;
    position: absolute;
    top: 10%;
    right: -20px;
    background-image: linear-gradient(to bottom, #222222, #E50014); }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .linija .asd:after {
    all: unset; }
  .selectLanguage {
    color: white;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 22; }
    .selectLanguage a {
      text-decoration: none;
      color: white;
      padding: 10px; } }

.articleMenu {
  margin-top: 22px;
  display: -webkit-flex;
  display: flex;
  position: relative;
  width: 86%;
  /*&:after {
        height: 1px;
        width: 100%;
        background: #DCDCDC;
        z-index: 0;
        content: ' ';
        position: absolute;
        top: 30px;
        left: 0px;
        width: 100%;
    }*/ }
  .articleMenu > .container > .row {
    background-color: #E50014;
    padding: 14px 15px; }
  .articleMenu .nav-item {
    list-style-type: none;
    padding-left: 68px; }
    .articleMenu .nav-item:first-child {
      padding-left: 0px; }
  .articleMenu .nav-link {
    color: #fff !important;
    font-size: 22px;
    line-height: 20px;
    padding: 0px;
    cursor: pointer; }
  .articleMenu .active {
    position: relative;
    color: black; }
    .articleMenu .active::before {
      height: 5px;
      width: 5px;
      background: #fff;
      z-index: 1;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      content: ' ';
      position: absolute;
      top: 32px;
      left: 50%; }
    .articleMenu .active:after {
      height: 4px;
      width: 100%;
      background: #fff;
      z-index: 1;
      content: ' ';
      position: absolute;
      top: 30px;
      left: 0; }

.card-flip {
  margin-bottom: 30px; }
  .card-flip .front {
    box-shadow: 0px 2px 4px 0px #dbdbdb; }
    .card-flip .front .watermark {
      position: absolute;
      z-index: 2;
      top: 12px;
      left: 8px; }

.flip {
  transition: 0.5s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  position: relative;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg); }

.front {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.back {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px #dbdbdb; }

.front {
  z-index: 2;
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg); }

.back {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg); }

.flip-image a {
  width: 100%;
  height: 100%; }
  .flip-image a img {
    height: 100% !important;
    width: 100%;
    object-fit: cover;
    object-position: center; }

.articleBox {
  margin-top: 40px;
  position: relative; }
  .articleBox .btn {
    background: transparent;
    border: 1px solid #E50014;
    color: #222222;
    font-size: 13px;
    line-height: 18px;
    border-radius: 6px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    right: 14px;
    margin-top: 5px; }
    .articleBox .btn img {
      margin-right: 12px;
      position: relative;
      top: -1px; }
  .articleBox .nav-link {
    text-decoration: none;
    padding: 0px; }
  .articleBox .container {
    padding: 0px; }
  .articleBox .textBox {
    background: white;
    border-top: 1px solid #DCDCDC;
    margin-bottom: 30px;
    height: 185px;
    position: relative; }
    .articleBox .textBox h3 {
      font-size: 18px;
      line-height: 20px;
      color: #222222;
      padding: 10px 20px;
      margin-bottom: 0; }
      .articleBox .textBox h3 span {
        color: #E50014;
        font-size: 16px; }
    .articleBox .textBox h2 {
      font-size: 20px;
      line-height: 20px;
      color: #E50014;
      text-align: right;
      padding: 3px 20px;
      margin-bottom: 0;
      padding-left: 0; }
    .articleBox .textBox .original-price {
      text-decoration: line-through;
      color: #cacaca; }
    .articleBox .textBox .textWrapper {
      position: absolute;
      display: -webkit-flex;
      display: flex;
      width: 100%;
      bottom: 16px; }
      .articleBox .textBox .textWrapper input[type=text] {
        width: 40%;
        margin-left: 14px;
        height: 32px;
        margin-top: 5px;
        padding-left: 12px; }
      .articleBox .textBox .textWrapper svg {
        fill: none;
        stroke: #E50014;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 1px;
        height: 20px;
        margin-right: 6px; }
  .articleBox .imageBox {
    background: white;
    position: relative;
    height: 225px; }
    .articleBox .imageBox img {
      position: absolute;
      top: 0;
      left: 0;
      background: white;
      width: 100%;
      object-fit: contain;
      height: 100%; }
    .articleBox .imageBox .watermark {
      top: 8px;
      left: 9px;
      z-index: 1;
      position: absolute;
      background: transparent;
      width: unset;
      padding: 0px;
      height: unset; }

.specialArticleBoxWrapper h4 {
  color: #222222;
  font-size: 22px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 18px; }

.specialArticleBoxWrapper .container {
  margin-top: 0px !important;
  padding-top: 40px !important;
  padding-bottom: 20px !important; }

.specialArticleBoxWrapper .imageBox {
  height: 250px;
  float: left;
  width: 50%; }
  .specialArticleBoxWrapper .imageBox img {
    height: 100%; }

.specialArticleBoxWrapper .textWrapper {
  bottom: 0 !important;
  padding: 0 20px; }
  .specialArticleBoxWrapper .textWrapper input[type=text] {
    width: 108px !important;
    height: 32px;
    margin-left: 0 !important;
    margin-top: 5px;
    padding-left: 12px;
    position: absolute;
    bottom: 60px; }

.specialArticleBoxWrapper .textBox {
  height: 250px;
  float: right;
  width: 50%;
  border-top: 0px;
  border-bottom: 0px;
  border-left: 1px solid #DCDCDC; }
  .specialArticleBoxWrapper .textBox h3 {
    font-weight: 700; }
  .specialArticleBoxWrapper .textBox h2 {
    font-weight: 700;
    font-size: 18px;
    text-align: left;
    padding-left: 20px; }
  .specialArticleBoxWrapper .textBox .original-price {
    text-decoration: line-through;
    color: #cacaca; }
  .specialArticleBoxWrapper .textBox .btn {
    right: unset;
    margin-top: 0;
    bottom: 20px; }

.specialArticleBoxWrapper .link {
  color: #E50014;
  text-decoration: underline;
  position: relative; }
  .specialArticleBoxWrapper .link:before {
    content: " " !important;
    display: block !important;
    border-left: 2.5px solid #E50014 !important;
    border-top: 2.5px solid #E50014 !important;
    width: 12px !important;
    height: 12px !important;
    -webkit-transform: rotate(130deg) !important;
            transform: rotate(130deg) !important;
    position: absolute;
    left: -20px;
    top: 6px; }

.breadcrumbLink {
  list-style: none !important;
  display: -webkit-flex;
  display: flex; }
  .breadcrumbLink .container {
    background: #F5F5F5 !important; }
  .breadcrumbLink .nav-item {
    margin-top: 40px; }
    .breadcrumbLink .nav-item::after {
      content: "//";
      font-size: 18px;
      color: #222222;
      line-height: 24px; }
  .breadcrumbLink .nav-item:last-child::after {
    content: ''; }
  .breadcrumbLink .nav-item:last-child .nav-link:last-child {
    color: #E50014;
    font-size: 16px;
    font-weight: 700;
    padding-right: 0px; }
  .breadcrumbLink .nav-link {
    display: inline;
    color: black;
    font-size: 16px;
    line-height: 20px;
    padding: 10px; }

.naslov {
  text-align: center;
  padding-top: 30px;
  position: relative; }
  .naslov h1 {
    font-size: 65px;
    font-weight: 700;
    margin-bottom: 0; }
  .naslov p {
    font-size: 18px;
    margin-bottom: 0; }

.article-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.5); }
  .article-modal .content {
    position: fixed;
    top: 20%;
    left: 50%;
    width: 800px;
    margin-left: -400px;
    background: white;
    margin-top: 30px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    padding: 25px 35px 25px 65px;
    display: -webkit-flex;
    display: flex; }
    .article-modal .content .artikal {
      max-width: 288px; }
    .article-modal .content h1 {
      font-size: 32px;
      line-height: 36px;
      color: #222222;
      margin-top: 36px;
      margin-bottom: 26px; }
    .article-modal .content hr {
      background: #E50014; }
    .article-modal .content .price {
      font-size: 36px;
      line-height: 36px;
      color: #E50014;
      margin-top: 26px; }
    .article-modal .content .spacer {
      margin-top: 10px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
    .article-modal .content .btn1 {
      background: white;
      color: #222222;
      border: 1px solid #E50014;
      padding: 11px 46px 11px 18px;
      font-size: 17px;
      line-height: 24px;
      font-weight: 500; }
      .article-modal .content .btn1 svg {
        fill: none;
        stroke: #E50014;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 1px;
        margin-right: 12px; }
    .article-modal .content .btn2 {
      background: #E50014;
      color: #FFFFFF;
      border: 1px solid #E50014;
      padding: 12px 39px 12px 39px;
      font-size: 17px;
      line-height: 24px;
      font-weight: 500; }
      .article-modal .content .btn2 i {
        margin-right: 16px; }
    .article-modal .content .input-wrap {
      margin-bottom: 16px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .article-modal .content {
    width: 90%;
    top: 20% !important;
    left: 50% !important;
    margin-left: -45%;
    position: fixed;
    display: block;
    padding: 0px;
    padding-bottom: 30px; }
    .article-modal .content .spacer {
      -webkit-justify-content: space-between;
              justify-content: space-between;
      display: -webkit-flex;
      display: flex; }
    .article-modal .content .spacer2 {
      margin-top: 30px; }
      .article-modal .content .spacer2 p {
        display: block; }
    .article-modal .content h1 {
      margin-top: 0; }
    .article-modal .content .btn1 {
      margin-left: unset;
      padding: 8px 15px 8px 15px; }
    .article-modal .content .btn2 {
      margin-left: unset;
      padding: 9px 22px 9px 22px; }
    .article-modal .content .artikal {
      max-width: unset; }
  .breadcrumbLink .nav-item {
    top: 20px; }
  .naslov h1 {
    font-size: 38px; }
  .divider:nth-child(2n) {
    padding-left: 7px; }
  .divider:nth-child(2n+1) {
    padding-right: 7px; }
  .imageBox {
    height: unset !important; }
    .imageBox img {
      position: unset !important; }
    .imageBox .watermark {
      position: relative !important; }
  .textBox {
    padding: 0px !important; }
    .textBox h3 {
      font-size: 14px !important; }
    .textBox h2 {
      font-size: 16px !important; }
    .textBox .btn {
      font-size: 0px !important; }
      .textBox .btn svg {
        margin-right: 0px !important;
        top: 0px !important; }
  .articleMenu .nav-item {
    padding-left: 20px; }
    .articleMenu .nav-item .nav-link {
      font-size: 16px; }
  .specialArticleBoxWrapper .container {
    padding-top: 20px !important;
    margin-top: -2px !important; }
  .specialArticleBoxWrapper .imageBox {
    height: 180px !important; }
  .specialArticleBoxWrapper .textBox {
    height: 180px !important; }
  .specialArticleBoxWrapper .textWrapper input[type=text] {
    width: 35% !important;
    height: 32px;
    margin-left: 0 !important;
    margin-top: 5px;
    padding-left: 12px;
    position: absolute;
    bottom: 20px; }
  .specialArticleBoxWrapper .textWrapper .btn {
    right: 20px; }
  .specialArticleBoxWrapper .link {
    margin-left: 15px;
    margin-bottom: 20px; } }

.pop-up-messages {
  position: fixed;
  right: 32px;
  top: 275px;
  z-index: 999999;
  width: 400px; }
  @media only screen and (max-width: 768px) {
    .pop-up-messages {
      width: 90%;
      margin-left: 5%;
      top: 0;
      margin-top: 50px;
      left: 0;
      right: unset; } }
  .pop-up-messages > div {
    padding: 42px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    margin-bottom: 16px;
    position: relative;
    cursor: pointer; }
    .pop-up-messages > div .hide {
      color: #FFFFFF;
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 22px;
      cursor: pointer; }
    .pop-up-messages > div > p {
      color: #FFFFFF;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      margin-bottom: 0; }
      .pop-up-messages > div > p::before {
        content: ' ';
        display: block;
        width: 10px;
        height: 20px;
        border-bottom: 3px solid #67af8a;
        border-right: 3px solid #67af8a;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        float: left;
        margin-right: 16px; }
    .pop-up-messages > div .error::before {
      display: none; }
  .pop-up-messages .hide-message {
    -webkit-animation-name: hide-message;
            animation-name: hide-message;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0); }

@-webkit-keyframes hide-message {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0); } }

@keyframes hide-message {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0); } }
  .pop-up-messages .fa-times {
    color: #DB3838;
    font-size: 24px;
    margin-right: 8px; }

.header-text-image {
  max-width: 100%;
  max-height: 24px;
  margin-top: 10px; }

.orders-statistics {
  font-size: 16px;
  float: right; }

.offer-tag {
  position: absolute;
  top: 10px;
  right: 0;
  background-color: #E50014;
  color: #FFFFFF;
  padding: 3px 5px;
  font-size: 12px;
  font-weight: 600; }

.viber {
  position: fixed;
  bottom: 32px;
  right: 32px; }
  @media only screen and (max-width: 1200px) {
    .viber {
      right: 32px;
      bottom: 60px;
      z-index: 9999; } }
  .viber img {
    width: 52px;
    height: 52px;
    background-color: #FFFFFF;
    border-radius: 50%; }
    @media only screen and (max-width: 1200px) {
      .viber img {
        width: 52px;
        height: 52px; } }

.header .btn-menu {
  display: none; }

.header .container {
  background: #222222; }

.header .navbarResposnive {
  display: none; }

.header .navbarResposnive2 {
  display: none; }

.header h6 {
  color: #E50014;
  font-family: "Neuropol X" !important;
  font-size: 26px;
  line-height: 34px;
  text-shadow: 0.7px 0.7px 0 white, -0.7px -0.7px 0 white, 0.7px -0.7px 0 white, -0.7px 0.7px 0 white, 0.7px 0.7px 0 white; }

.header p {
  font-size: 14px;
  color: white;
  line-height: 16px;
  padding-top: 12px;
  padding-bottom: 12px; }
  .header p img {
    margin-right: 10px;
    margin-top: -2px; }

.header .spacer {
  position: relative;
  left: -50px; }

.header .fb {
  margin-left: 10px;
  position: relative;
  left: 6px; }

.header .instagram {
  position: relative;
  left: 12px; }

.header .logo {
  position: absolute;
  margin-top: 5px;
  z-index: 2; }

.header .linija .container {
  padding: 0px; }

.header .linija hr {
  border-top: 3px solid white;
  border-bottom: 3px solid white;
  height: 6px;
  background: #E50014;
  margin-top: 0px;
  margin-bottom: 0px; }

.header .navbar {
  padding: 0px; }
  .header .navbar .container {
    background: black; }
  .header .navbar .col-xl-7 {
    padding: 0px; }
  .header .navbar .nav-item {
    padding-left: 0px;
    padding-right: 18px; }
    .header .navbar .nav-item:last-child {
      padding-right: 0px; }
    .header .navbar .nav-item .nav-link {
      color: white;
      font-size: 14px;
      line-height: 20px; }

.search {
  margin-top: -16px; }
  .search .container {
    background: white;
    border-top: 4px solid #E50014;
    border-bottom: 6px solid #FFF100; }
  .search .input-group {
    margin-top: 16px;
    margin-bottom: 20px;
    height: 46px; }
    .search .input-group .form-control {
      border-radius: 23px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      height: 46px; }
      .search .input-group .form-control::-webkit-input-placeholder {
        padding-left: 6px; }
      .search .input-group .form-control::-ms-input-placeholder {
        padding-left: 6px; }
      .search .input-group .form-control::placeholder {
        padding-left: 6px; }
    .search .input-group .btn {
      border-radius: 23px;
      background: #E50014;
      border: 0;
      outline: none;
      width: 65px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .search .searchForm {
    background: white;
    position: absolute;
    top: 62px;
    width: 350px;
    z-index: 5;
    padding: 10px;
    box-shadow: 2px 8px 8px 0 rgba(0, 0, 0, 0.1); }
    .search .searchForm a {
      text-decoration: none;
      margin-left: 5px;
      margin-right: 5px;
      width: 100%; }
      .search .searchForm a:hover {
        background: #F5F5F5; }
    .search .searchForm .searchProduct {
      display: -webkit-flex;
      display: flex;
      width: 100%;
      padding: 10px; }
      .search .searchForm .searchProduct ul {
        list-style: none;
        width: 100%;
        padding: 0px;
        margin: 0; }
    .search .searchForm p {
      color: #222222;
      font-size: 16px;
      margin-bottom: 0; }
      .search .searchForm p span {
        color: #E50014; }

.account {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .account .isvg {
    margin-right: 5px; }
    .account .isvg svg {
      fill: black; }
  .account a {
    color: #222222;
    font-size: 16px;
    margin: 0 5px;
    text-decoration: none; }

.cart {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 10px; }
  .cart .cart-button {
    position: relative;
    float: left;
    width: 100%;
    height: 50px;
    border-radius: 6px;
    background-color: transparent;
    border: none;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 10px;
    z-index: 5; }
    .cart .cart-button .number {
      position: absolute;
      top: 10px;
      right: -12px;
      border-radius: 50%;
      color: white;
      width: 18px;
      height: 18px;
      font-size: 14px;
      font-weight: 600;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      background: #E50014; }
    .cart .cart-button svg {
      fill: none;
      stroke: black;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2px; }

.cart2 {
  display: none; }

.zastava img {
  position: absolute;
  right: -1px;
  top: -82px;
  z-index: 1; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .header .container {
    display: none; }
  .header .linija .container {
    display: unset; }
    .header .linija .container hr {
      height: 4px; }
    .header .linija .container::after {
      display: block;
      content: ' ';
      height: 8px;
      background-color: #222222;
      width: 100%; }
  .header .search .container {
    display: block;
    border-bottom: 2px solid #FFF100; }
    .header .search .container .row {
      -webkit-align-items: center;
              align-items: center; }
  .header .search .searchForm {
    width: 270px;
    left: 0;
    top: 70px; }
  .header .logo {
    all: unset;
    margin-top: 6px;
    width: 60px; }
  .header .zastava {
    display: none; }
  .header .btn-menu {
    background: transparent;
    border: 0;
    display: block !important;
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  .header .input-group {
    margin-top: 30px;
    margin-left: 10px; }
    .header .input-group .btn {
      width: 34px; }
      .header .input-group .btn img {
        width: 16px;
        margin-left: -6px;
        margin-top: -2px; }
    .header .input-group ::-webkit-input-placeholder {
      font-size: 12px; }
    .header .input-group ::-ms-input-placeholder {
      font-size: 12px; }
    .header .input-group ::placeholder {
      font-size: 12px; }
    .header .input-group .form-control {
      height: 34px !important; }
  .header .input-group-append {
    height: 34px !important; }
  .cart2 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .cart2 .cart-button {
      position: relative;
      height: 38px;
      border-radius: 6px;
      background-color: transparent;
      border: none;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .cart2 .cart-button svg {
        fill: none;
        stroke: #222222;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2px; }
  .navbarResposnive {
    display: block !important;
    position: fixed;
    -webkit-transform: translate3d(-110%, 0, 0);
    transform: translate3d(-110%, 0, 0); }
  .navbarResposniveOpen {
    display: block !important;
    cursor: pointer;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
    left: 0;
    top: 0;
    position: fixed;
    height: 100%;
    z-index: 244; }
    .navbarResposniveOpen .nav-item {
      list-style: none; }
    .navbarResposniveOpen .nav-link {
      color: #222222; }
  .sadrzaj {
    height: 100%;
    -webkit-transform: translate3d(-110%, 0, 0);
    transform: translate3d(-110%, 0, 0); }
  .sadrzajOpen {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transition-duration: 1s;
    background-color: #E50014;
    height: 100%;
    width: 300px; }
    .sadrzajOpen .content {
      background: white;
      height: 100%; }
      .sadrzajOpen .content .isvg svg {
        width: 24px;
        height: 24px;
        color: black;
        margin-right: 20px; }
      .sadrzajOpen .content .user-area {
        background: #222222;
        height: 160px;
        margin-bottom: 30px; }
        .sadrzajOpen .content .user-area .account .nav-link {
          color: white;
          display: inline;
          padding: 0px !important;
          font-size: 16px; }
        .sadrzajOpen .content .user-area .account p {
          display: unset;
          position: absolute;
          top: 100px;
          width: 200px;
          font-size: 20px; }
        .sadrzajOpen .content .user-area .account .isvg {
          top: 20px;
          background: rgba(255, 255, 255, 0.6);
          width: 64px;
          height: 64px;
          border-radius: 50px;
          position: relative; }
          .sadrzajOpen .content .user-area .account .isvg svg {
            display: block;
            margin: auto;
            margin-top: 20px; }
  .navbarResposnive2 {
    display: block !important;
    position: fixed;
    -webkit-transform: translate3d(110%, 0, 0);
    transform: translate3d(110%, 0, 0);
    right: 0; }
  .navbarResposniveOpen2 {
    display: block !important;
    cursor: pointer;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    right: 0;
    top: 0;
    position: fixed;
    height: 100%;
    z-index: 16; }
    .navbarResposniveOpen2 ul {
      list-style: none; }
      .navbarResposniveOpen2 ul li .submenu {
        display: none; }
      .navbarResposniveOpen2 ul li:hover .submenu {
        display: block; }
      .navbarResposniveOpen2 ul .nav-link {
        color: black;
        padding: 0px;
        margin-top: 10px; }
  .sadrzaj2 {
    height: 100%;
    -webkit-transform: translate3d(200%, 0, 0);
    transform: translate3d(200%, 0, 0); }
  .sadrzajOpen2 {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transition-duration: 1s;
    height: 100%;
    width: 300px;
    float: right; }
    .sadrzajOpen2 .content {
      background: white;
      height: 100%; }
      .sadrzajOpen2 .content .nav-link {
        color: black;
        list-style-type: none;
        text-align: left;
        font-size: 16px; }
    .sadrzajOpen2 h3 {
      background: #222222;
      color: white;
      text-align: left;
      font-size: 20px !important;
      padding-left: 40px;
      padding-top: 20px;
      height: 60px; } }

@media screen and (max-width: 1199px) and (min-width: 768px) {
  .header .spacer {
    left: -20px; } }

@media screen and (max-width: 1199px) and (min-width: 991px) {
  .zastava img {
    position: absolute;
    right: -1px;
    top: -52px;
    z-index: 1; } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .header .linija .container {
    display: unset; }
    .header .linija .container hr {
      height: 4px; }
    .header .linija .container::after {
      display: block;
      content: ' ';
      height: 8px;
      background-color: #222222;
      width: 100%; }
  .categories {
    display: none; }
  .btn-categories {
    display: block;
    background: transparent;
    border: 0; }
    .btn-categories img {
      height: 32px;
      width: 32px; }
  .btn-menu {
    display: block !important;
    background: transparent;
    border: 0;
    display: block !important;
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  .btn-categories {
    display: block !important; }
  .search .container {
    display: block; }
    .search .container .row {
      -webkit-align-items: center;
              align-items: center; }
  .search .searchForm {
    width: 330px; }
  .search .logo {
    position: unset; }
  .search .cart2 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .search .cart2 .cart-button {
      position: relative;
      height: 38px;
      border-radius: 6px;
      background-color: transparent;
      border: none;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .search .cart2 .cart-button svg {
        fill: none;
        stroke: #222222;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2px; }
  .navbarResposnive {
    display: block !important;
    position: fixed;
    -webkit-transform: translate3d(-110%, 0, 0);
    transform: translate3d(-110%, 0, 0); }
  .navbarResposniveOpen {
    display: block !important;
    cursor: pointer;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
    left: 0;
    top: 0;
    position: fixed;
    height: 100%;
    z-index: 244; }
    .navbarResposniveOpen .nav-item {
      list-style: none; }
    .navbarResposniveOpen .nav-link {
      color: #222222; }
  .sadrzaj {
    height: 100%;
    -webkit-transform: translate3d(-110%, 0, 0);
    transform: translate3d(-110%, 0, 0); }
  .sadrzajOpen {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transition-duration: 1s;
    background-color: #E50014;
    height: 100%;
    width: 300px; }
    .sadrzajOpen .content {
      background: white;
      height: 100%; }
      .sadrzajOpen .content .isvg svg {
        width: 24px;
        height: 24px;
        color: black;
        margin-right: 20px; }
      .sadrzajOpen .content .user-area {
        background: #222222;
        height: 160px;
        margin-bottom: 30px; }
        .sadrzajOpen .content .user-area .account .nav-link {
          color: white;
          display: inline;
          padding: 0px !important;
          font-size: 16px; }
        .sadrzajOpen .content .user-area .account p {
          display: unset;
          position: absolute;
          top: 100px;
          width: 200px;
          font-size: 20px; }
        .sadrzajOpen .content .user-area .account .isvg {
          top: 20px;
          background: rgba(255, 255, 255, 0.6);
          width: 64px;
          height: 64px;
          border-radius: 50px;
          position: relative; }
          .sadrzajOpen .content .user-area .account .isvg svg {
            display: block;
            margin: auto;
            margin-top: 20px; }
  .navbarResposnive2 {
    display: block !important;
    position: fixed;
    -webkit-transform: translate3d(110%, 0, 0);
    transform: translate3d(110%, 0, 0);
    right: 0; }
  .navbarResposniveOpen2 {
    display: block !important;
    cursor: pointer;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    right: 0;
    top: 0;
    position: fixed;
    height: 100%;
    z-index: 16; }
    .navbarResposniveOpen2 ul {
      list-style: none; }
      .navbarResposniveOpen2 ul li .submenu {
        display: none; }
      .navbarResposniveOpen2 ul li:hover .submenu {
        display: block; }
      .navbarResposniveOpen2 ul .nav-link {
        color: black;
        padding: 0px;
        margin-top: 10px; }
  .sadrzaj2 {
    height: 100%;
    -webkit-transform: translate3d(200%, 0, 0);
    transform: translate3d(200%, 0, 0); }
  .sadrzajOpen2 {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transition-duration: 1s;
    height: 100%;
    width: 300px;
    float: right; }
    .sadrzajOpen2 .content {
      background: white;
      height: 100%; }
      .sadrzajOpen2 .content .nav-link {
        color: black;
        list-style-type: none;
        text-align: left;
        font-size: 16px; }
    .sadrzajOpen2 h3 {
      background: #222222;
      color: white;
      text-align: left;
      font-size: 20px !important;
      padding-left: 40px;
      padding-top: 20px;
      height: 60px; } }

@media screen and (max-width: 991px) and (min-width: 320px) {
  .navbar-nav {
    display: none !important; } }

.sliderWrapper .sliderBg {
  background-repeat: no-repeat;
  margin-top: 38px;
  padding-bottom: 15px; }

.sliderWrapper .carousel-indicators {
  left: 0;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-right: 0;
  margin-left: 50px; }
  .sliderWrapper .carousel-indicators li {
    width: 10px;
    height: 0px;
    border-radius: 100%;
    background: #C8C8C8;
    border-top: 0px;
    margin-left: 6px; }
    .sliderWrapper .carousel-indicators li:first-child {
      margin-left: 0px; }
  .sliderWrapper .carousel-indicators .active {
    background: #E50014;
    width: 34px;
    height: 10px;
    border-radius: 0px;
    border-bottom: 0;
    border-radius: 6px; }

.sliderWrapper .content {
  position: absolute;
  top: 40px;
  left: 50px; }
  .sliderWrapper .content h6 {
    color: #E50014;
    font-size: 18px;
    line-height: 18px; }
  .sliderWrapper .content h3 {
    margin-top: 10px;
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
    color: #222222;
    margin-bottom: 0; }
  .sliderWrapper .content p {
    font-size: 16px;
    line-height: 20px;
    color: #222222;
    padding-top: 15px;
    position: relative;
    margin: 0; }
  .sliderWrapper .content .btn {
    background: #E50014;
    outline: none;
    border: none;
    font-size: 18px;
    line-height: 18px;
    color: white;
    padding-top: 12px;
    padding-bottom: 10px;
    padding-right: 23px;
    padding-left: 23px;
    border-radius: 6px;
    margin-top: 30px; }
    .sliderWrapper .content .btn img {
      margin-left: 15px;
      left: unset;
      top: -2px; }

.kategorije {
  display: none; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .slika {
    float: right;
    width: 200px;
    position: unset !important; }
  .content {
    left: 20px !important;
    top: 20px !important; }
    .content h3 {
      font-size: 16px !important;
      line-height: 26px !important; }
    .content p {
      display: none; }
  .carousel-indicators {
    margin-left: 20px !important; }
  .sliderBg {
    background: url(/static/media/phoneBg.6ac75a72.png) !important;
    padding-bottom: 0px !important; }
    .sliderBg .btn {
      margin-top: 10px !important;
      font-size: 18px !important;
      padding-left: 12px !important;
      padding-right: 12px !important; }
      .sliderBg .btn img {
        margin-left: 4px !important; }
  .sliderWrapper .sliderBg {
    margin-top: 15px !important; } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .sliderWrapper .box {
    width: 50%;
    padding: 18px; }
    .sliderWrapper .box:last-child {
      margin-top: 38px !important;
      margin-left: 7px !important; }
    .sliderWrapper .box:first-child {
      margin-right: 7px !important; } }

@media screen and (max-width: 1280px) and (min-width: 768px) {
  .pic {
    width: 110px !important; } }

.carousel-control-prev {
  z-index: 2 !important;
  width: 36px !important;
  height: 36px !important;
  position: absolute !important;
  right: -68px !important;
  bottom: 0 !important;
  background: white !important;
  outline: none !important;
  left: unset !important;
  top: -5px !important;
  opacity: 1 !important;
  box-shadow: 0px 2px 2px 0px #d8d9d9; }
  .carousel-control-prev::after {
    content: " " !important;
    display: block !important;
    border-left: 2px solid #E50014 !important;
    border-top: 2px solid #E50014 !important;
    width: 12px !important;
    height: 12px !important;
    -webkit-transform: rotate(-45deg) !important;
            transform: rotate(-45deg) !important;
    position: relative;
    left: 2px; }

.carousel-control-next {
  z-index: 2 !important;
  width: 36px !important;
  height: 36px !important;
  position: absolute !important;
  right: -115px !important;
  bottom: 0 !important;
  background: white !important;
  outline: none !important;
  left: unset !important;
  top: -5px !important;
  opacity: 1 !important;
  box-shadow: 0px 2px 2px 0px #d8d9d9; }
  .carousel-control-next::after {
    content: " " !important;
    display: block !important;
    border-left: 2px solid #E50014 !important;
    border-top: 2px solid #E50014 !important;
    width: 12px !important;
    height: 12px !important;
    -webkit-transform: rotate(135deg) !important;
            transform: rotate(135deg) !important;
    position: relative;
    right: 2px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .carousel-control-next {
    right: -16% !important;
    top: -6px !important; }
  .carousel-control-prev {
    right: -1% !important;
    top: -6px !important; } }

@media screen and (max-width: 1199px) and (min-width: 768px) {
  .carousel-control-next {
    right: -16% !important;
    top: -6px !important; }
  .carousel-control-prev {
    right: -8% !important;
    top: -6px !important; } }

.categories {
  margin-top: 22px;
  background-color: #FFFFFF;
  float: left;
  margin-bottom: 15px; }
  .categories .submenu {
    display: none; }
  .categories h3 {
    background-color: #E50014;
    color: #FFFFFF;
    padding: 15px 22px;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 10px; }
  .categories ul {
    list-style: none;
    padding: 0px;
    margin: 0;
    border: 1px solid #DCDCDC;
    border-top: none;
    float: left; }
    .categories ul > li {
      border-bottom: 1px solid #DCDCDC;
      width: 100%;
      padding-left: 20px;
      padding-right: 10px;
      float: left; }
      .categories ul > li::after {
        content: " ";
        display: block;
        border-top: 1px solid #E50014;
        border-right: 1px solid #E50014;
        width: 8px;
        height: 8px;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        float: right;
        margin-top: 14px;
        margin-right: 4px; }
      .categories ul > li:last-child {
        border-bottom: none; }
      .categories ul > li a {
        float: left;
        color: #222222;
        padding: 5px 0; }
      .categories ul > li:hover .submenu {
        margin-top: 22px;
        height: 670px;
        position: absolute;
        left: 267px;
        background: white;
        z-index: 16;
        top: 0;
        padding-right: 40px;
        border-top: 3px solid #E50014;
        border-bottom: 3px solid #E50014;
        border-right: 3px solid #E50014;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-flex-direction: column;
                flex-direction: column;
        min-width: 858px; }
        .categories ul > li:hover .submenu h5 {
          font-size: 22px;
          font-weight: 600;
          line-height: 26px;
          color: #222222;
          margin-left: 13px;
          margin-top: 15px; }
        .categories ul > li:hover .submenu li {
          margin-left: 13px;
          float: none;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: space-between;
                  justify-content: space-between;
          width: 236px; }
          .categories ul > li:hover .submenu li:after {
            margin-right: 0px; }
          .categories ul > li:hover .submenu li:last-child {
            border-bottom: 1px solid #CCCCCC;
            margin-bottom: 14px; }
        .categories ul > li:hover .submenu ul {
          border: none; }

.btn-categories {
  display: none; }

.categoryPage {
  margin-top: -50px; }
  .categoryPage .subcat-head {
    background-color: #E50014;
    color: #fff;
    padding: 8px 12px;
    font-size: 18px;
    font-weight: 700; }
  .categoryPage .articleBox {
    margin-top: 0; }
  .categoryPage .naslovnaSlika {
    margin-top: 80px; }
  .categoryPage .selectMenu {
    margin-top: 16px;
    display: -webkit-flex;
    display: flex;
    position: relative;
    width: 100%;
    background-color: #E50014;
    padding: 12px 16px;
    margin-bottom: 16px; }
    .categoryPage .selectMenu > .container {
      background-color: #E50014; }
      .categoryPage .selectMenu > .container .row {
        -webkit-align-items: center;
                align-items: center; }
    .categoryPage .selectMenu h3 {
      font-size: 28px;
      font-weight: 700;
      line-height: 20px;
      color: #FFFFFF;
      margin-bottom: 0; }
    .categoryPage .selectMenu .form-group {
      display: -webkit-box; }
      .categoryPage .selectMenu .form-group .form-control {
        width: 200px;
        position: relative; }
      .categoryPage .selectMenu .form-group select {
        -webkit-appearance: none; }
      .categoryPage .selectMenu .form-group svg {
        position: absolute;
        top: 15px;
        right: 19px;
        pointer-events: none; }
    .categoryPage .selectMenu label {
      margin-top: 8px;
      margin-right: 18px; }
    .categoryPage .selectMenu .selectWrapper {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .categoryPage .selectMenu .selectWrapper label {
        color: #FFFFFF; }
      .categoryPage .selectMenu .selectWrapper .form-group {
        margin-bottom: 0; }
      .categoryPage .selectMenu .selectWrapper .btn {
        height: 40px !important;
        margin-left: 20px;
        border-radius: 0px; }
        .categoryPage .selectMenu .selectWrapper .btn:last-child {
          margin-left: 10px; }
      .categoryPage .selectMenu .selectWrapper .active {
        background: red; }
  .categoryPage .pagination {
    margin-bottom: 50px; }
    .categoryPage .pagination li {
      background: #DCDCDC;
      margin-right: 10px;
      z-index: 4;
      height: 40px;
      width: 40px;
      text-align: center;
      padding-top: 8px;
      border-radius: 6px; }
      .categoryPage .pagination li a {
        color: #222222;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
        text-decoration: none;
        border: 0;
        outline: none;
        padding: 10px 15px; }
    .categoryPage .pagination .active {
      background: #E50014; }
      .categoryPage .pagination .active a {
        color: white; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .categoryPage .selectMenu h3 {
    font-size: 24px; }
  .categoryPage .selectMenu label {
    font-size: 12px; }
  .categoryPage .selectMenu .form-control {
    font-size: 14px; }
  .categoryPage .selectMenu .btn {
    display: none; }
  .categoryPage .selectWrapper {
    margin-top: 20px;
    width: 100%; }
    .categoryPage .selectWrapper .form-group {
      display: -webkit-flex;
      display: flex;
      width: 100%;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
  .categories {
    display: none; }
  .cats {
    display: none; }
  .btn-categories {
    display: block;
    background: transparent;
    border: 0; }
    .btn-categories img {
      height: 32px;
      width: 32px; } }

.cats {
  margin-top: 22px;
  margin-bottom: 16px;
  background-color: #FFFFFF;
  float: left;
  position: -webkit-sticky;
  position: sticky;
  top: 22px; }
  .cats .submenu {
    display: none; }
  .cats h3 {
    background-color: #E50014;
    color: #FFFFFF;
    padding: 15px 22px;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 10px; }
  .cats > ul {
    list-style: none;
    padding: 3px 16px;
    margin: 0;
    border: 1px solid #DCDCDC;
    border-top: none;
    float: left;
    width: 100%; }
    .cats > ul > li {
      border-bottom: 1px solid #DCDCDC;
      width: 100%;
      float: left; }
      .cats > ul > li::after {
        content: " ";
        display: block;
        border-top: 2px solid #E50014;
        border-right: 2px solid #E50014;
        width: 10px;
        height: 10px;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        float: right;
        margin-top: 16px;
        margin-right: 4px; }
      .cats > ul > li:last-child {
        border-bottom: none; }
      .cats > ul > li a {
        float: left;
        color: #222222;
        padding: 7px 0; }
      .cats > ul > li > a {
        font-size: 18px;
        font-weight: 600; }
      .cats > ul > li ul {
        width: 100%;
        float: left;
        list-style: none;
        padding-left: 14px;
        position: relative; }
        .cats > ul > li ul::after {
          content: " ";
          display: block;
          border-bottom: 2px solid #E50014;
          border-left: 2px solid #E50014;
          width: 8px;
          height: 8px;
          margin-top: 14px;
          position: absolute;
          top: 0;
          left: 0; }
        .cats > ul > li ul li {
          width: 100%;
          float: left; }
    .cats > ul .active {
      position: relative; }
      .cats > ul .active > a {
        font-weight: 900; }
      .cats > ul .active::after {
        position: absolute;
        top: 0;
        right: 0;
        -webkit-transform: rotate(135deg);
                transform: rotate(135deg); }

.mobile-categories {
  height: 100vh !important;
  overflow-y: auto;
  background-color: #FFFFFF;
  float: left;
  z-index: 999; }
  .mobile-categories .submenu {
    display: none; }
  .mobile-categories > ul {
    list-style: none;
    padding: 3px 16px;
    margin: 0;
    border: 1px solid #DCDCDC;
    border-top: none;
    float: left;
    width: 100%; }
    .mobile-categories > ul > li {
      border-bottom: 1px solid #DCDCDC;
      width: 100%;
      float: left; }
      .mobile-categories > ul > li::after {
        content: " ";
        display: block;
        border-top: 2px solid #E50014;
        border-right: 2px solid #E50014;
        width: 10px;
        height: 10px;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        float: right;
        margin-top: 16px;
        margin-right: 4px; }
      .mobile-categories > ul > li:last-child {
        border-bottom: none; }
      .mobile-categories > ul > li a {
        float: left;
        color: #222222;
        padding: 7px 0; }
      .mobile-categories > ul > li > a {
        font-size: 18px; }
      .mobile-categories > ul > li ul {
        width: 100%;
        float: left;
        list-style: none;
        padding-left: 14px;
        position: relative; }
        .mobile-categories > ul > li ul::after {
          content: " ";
          display: block;
          border-bottom: 2px solid #E50014;
          border-left: 2px solid #E50014;
          width: 8px;
          height: 8px;
          margin-top: 14px;
          position: absolute;
          top: 0;
          left: 0; }
        .mobile-categories > ul > li ul li {
          width: 100%;
          float: left; }
    .mobile-categories > ul .active {
      position: relative; }
      .mobile-categories > ul .active::after {
        position: absolute;
        top: 0;
        right: 0;
        -webkit-transform: rotate(135deg);
                transform: rotate(135deg); }

.specijalnaPonuda a {
  text-decoration: none !important; }

.specijalnaPonuda .box {
  background: url(/static/media/ponudaBg.b5ede7ba.png);
  background-repeat: no-repeat;
  padding: 21px;
  position: relative;
  height: 130px;
  text-decoration: none !important; }
  .specijalnaPonuda .box h6 {
    color: #E50014;
    font-size: 14px;
    line-height: 18px;
    text-decoration: none !important; }
  .specijalnaPonuda .box h3 {
    font-size: 28px;
    font-weight: 700;
    line-height: 20px;
    color: #222222;
    margin-bottom: 14px;
    text-decoration: none !important; }
  .specijalnaPonuda .box img {
    position: absolute;
    top: -20px;
    right: 0;
    width: 180px;
    object-fit: contain; }
  .specijalnaPonuda .box p {
    color: #222222;
    font-size: 15px;
    line-height: 20px;
    text-decoration: none !important; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .specijalnaPonuda {
    margin-top: -1px; }
    .specijalnaPonuda .box {
      background: url(/static/media/ponudaBgMobile.36c1106c.png) !important;
      margin-bottom: 30px;
      padding: 10px 10px; }
      .specijalnaPonuda .box img {
        width: 150px;
        top: 0px; }
      .specijalnaPonuda .box h3 {
        font-size: 20px; }
      .specijalnaPonuda .box p {
        font-size: 14px; } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .specijalnaPonuda .container {
    margin-top: -2px; }
  .specijalnaPonuda .box {
    background: url(/static/media/ponudaBgMobile.36c1106c.png) !important; }
    .specijalnaPonuda .box img {
      width: 130px !important;
      top: 0px !important; }
    .specijalnaPonuda .box h3 {
      font-size: 16px !important;
      margin-bottom: 0px; }
    .specijalnaPonuda .box p {
      font-size: 14px !important; } }

.homeNews {
  margin-bottom: -40px; }
  .homeNews .container {
    background: white !important;
    padding-top: 52px !important;
    padding-bottom: 72px !important; }
  .homeNews h1 {
    font-size: 65px;
    font-weight: 700;
    color: #222222; }
  .homeNews h4 {
    color: #222222;
    font-size: 32px;
    line-height: 20px;
    font-weight: 700;
    margin-bottom: 24px; }
  .homeNews p {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 70px; }
  .homeNews .link {
    color: #E50014;
    text-decoration: underline;
    position: relative; }
    .homeNews .link:before {
      content: " " !important;
      display: block !important;
      border-left: 2.5px solid #E50014 !important;
      border-top: 2.5px solid #E50014 !important;
      width: 12px !important;
      height: 12px !important;
      -webkit-transform: rotate(130deg) !important;
              transform: rotate(130deg) !important;
      position: absolute;
      left: -20px;
      top: 6px; }

.newsPage .homeNews .container {
  padding-top: 60px !important;
  background: #F5F5F5 !important; }

.newsBox {
  position: relative;
  height: 360px;
  margin-bottom: 40px; }
  .newsBox .nav-link {
    padding: 0px; }
  .newsBox h3 {
    font-size: 22px;
    line-height: 20px;
    color: #222222;
    padding-top: 11px; }
  .newsBox p {
    font-size: 18px;
    line-height: 22px;
    color: #222222;
    margin-bottom: 23px; }
  .newsBox .redParagraf {
    font-size: 14px;
    line-height: 20px;
    color: #E50014;
    margin-bottom: 8px; }
  .newsBox .btn {
    background: transparent;
    border: 1px solid #E50014;
    color: #E50014;
    position: relative;
    font-size: 16px;
    line-height: 20px;
    border-radius: 6px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 30px;
    padding-right: 7px;
    bottom: 0px;
    position: absolute; }
    .newsBox .btn:before {
      content: " " !important;
      display: block !important;
      border-left: 1px solid #E50014 !important;
      border-top: 1px solid #E50014 !important;
      width: 8px !important;
      height: 8px !important;
      -webkit-transform: rotate(130deg) !important;
              transform: rotate(130deg) !important;
      position: absolute;
      left: 11px;
      top: 10px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .homeNews {
    margin-top: -2px !important;
    margin-bottom: 0px; }
    .homeNews .container {
      padding-bottom: 20px !important; }
  .newsBox {
    height: unset;
    margin-bottom: 30px; }
    .newsBox .btn {
      position: relative;
      bottom: 0 !important; }
      .newsBox .btn::before {
        position: absolute; } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .newsBox {
    height: 380px;
    margin-top: 30px; }
    .newsBox .btn {
      bottom: 0px !important;
      position: absolute; } }

@media screen and (max-width: 1200px) and (min-width: 991px) {
  .newsBox {
    height: 420px;
    margin-top: 30px; }
    .newsBox .btn {
      bottom: 0px !important;
      position: absolute; } }

.detailNaslov > div {
  padding-top: 20px; }
  .detailNaslov > div h1 {
    font-size: 45px;
    color: #222222;
    font-weight: 700; }
  .detailNaslov > div .redParagraf {
    color: #E50014;
    margin-bottom: 0; }

.newsDetail > div {
  padding-top: 20px; }
  .newsDetail > div p {
    margin-top: 20px;
    margin-bottom: 0; }

.newsDetail .newsBox p {
  margin-top: 10px; }

@media screen and (max-width: 1280px) and (min-width: 991px) {
  .newsDetail .newsBox {
    margin-top: 0;
    height: 360px; } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .newsDetail .newsBox {
    margin-top: 0;
    height: 360px; } }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .newsDetail .newsBox:first-child {
    margin-top: 30px; }
  .newsDetail .newsBox p {
    margin-bottom: 22px; } }

.newsletterWrapper .newsletter {
  background: url(/static/media/NewsletterBg.9d205614.png) !important;
  background-repeat: no-repeat;
  padding: 28px 34px;
  -webkit-align-items: center;
          align-items: center; }
  .newsletterWrapper .newsletter h1 {
    font-size: 32px;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 25px; }
  .newsletterWrapper .newsletter p {
    font-size: 16px;
    line-height: 25px;
    color: #FFFFFF;
    margin-bottom: 0px; }
  .newsletterWrapper .newsletter .input-group {
    height: 46px; }
    .newsletterWrapper .newsletter .input-group .form-control {
      border-top-left-radius: 23px;
      border-bottom-left-radius: 23px;
      height: 46px; }
      .newsletterWrapper .newsletter .input-group .form-control::-webkit-input-placeholder {
        padding-left: 6px; }
      .newsletterWrapper .newsletter .input-group .form-control::-ms-input-placeholder {
        padding-left: 6px; }
      .newsletterWrapper .newsletter .input-group .form-control::placeholder {
        padding-left: 6px; }
    .newsletterWrapper .newsletter .input-group .btn {
      border-top-right-radius: 23px;
      border-bottom-right-radius: 23px;
      background: #222222;
      border: 0;
      font-size: 16px;
      outline: none;
      width: 120px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .newsletterWrapper {
    margin-top: -2px !important; }
    .newsletterWrapper .newsletter {
      padding: 0px !important;
      padding-top: 20px !important;
      padding-bottom: 20px !important; }
      .newsletterWrapper .newsletter .input-group {
        margin-top: 20px !important; }
        .newsletterWrapper .newsletter .input-group .form-control::-webkit-input-placeholder {
          padding-left: 0px !important; }
        .newsletterWrapper .newsletter .input-group .form-control::-ms-input-placeholder {
          padding-left: 0px !important; }
        .newsletterWrapper .newsletter .input-group .form-control::placeholder {
          padding-left: 0px !important; }
        .newsletterWrapper .newsletter .input-group .btn {
          width: auto !important;
          font-size: 12px !important; } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .newsletterWrapper {
    margin-top: -2px !important; }
    .newsletterWrapper .newsletter .input-group {
      margin-top: 20px !important; } }

.googleMap .container {
  padding: 0px !important;
  position: relative; }

.googleMap .map {
  height: 460px;
  margin-top: 60px; }

.googleMap .yellowLine {
  background: #FFF100;
  height: 8px;
  position: absolute;
  bottom: 17px;
  width: 100%;
  margin: 0; }

.googleMap .redLine {
  background: #E50014;
  height: 8px;
  position: absolute;
  bottom: 4px;
  width: 100%;
  margin: 0; }

.googleMap .mapZastava {
  position: absolute;
  right: 0;
  bottom: -40px; }

.blackLine {
  background: black !important;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .blackLine p {
    color: white;
    margin: 0;
    font-weight: 500;
    font-size: 20px; }
  .blackLine img {
    height: 20px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .googleMap {
    margin-top: -2px; }
    .googleMap .mapZastava {
      bottom: -35px;
      width: 50%; }
    .googleMap .map {
      height: 350px; }
  .blackLine p {
    font-size: 16px; }
  .blackLine img {
    height: 16px; } }

.footer .btn {
  background: #E50014;
  outline: none;
  border: none;
  font-size: 18px;
  line-height: 18px;
  color: white;
  padding-top: 12px;
  padding-bottom: 10px;
  padding-right: 23px;
  padding-left: 23px;
  border-radius: 6px;
  margin-top: 30px; }
  .footer .btn img {
    margin-left: 15px;
    left: unset;
    top: -2px; }

.footer .container {
  background: #222222 !important; }

.footer .footerContent {
  padding-top: 40px; }

.footer .logo {
  margin-bottom: 24px;
  width: 220px; }

.footer p {
  color: white;
  font-size: 18px;
  line-height: 26px; }

.footer h5 {
  color: white;
  font-size: 22px;
  font-weight: 700;
  line-height: 25px;
  margin-bottom: 26px; }

.footer .nav-link {
  color: white;
  font-size: 18px;
  line-height: 25px;
  padding: 0px;
  padding-bottom: 14px; }

.footer .form-control {
  background: #222222 !important;
  border-radius: 0px; }

.footer input {
  margin-bottom: 10px !important;
  border: 1px solid #707070;
  height: 35px; }

.footer textarea {
  border: 1px solid #707070;
  height: 65px !important; }

.footer .phone {
  margin-right: 20px; }

.footer .location {
  margin-right: 26px; }

.footer .email {
  margin-right: 16px; }

.footer .info {
  margin-left: -44px; }
  .footer .info .paragraf {
    margin-left: 37px;
    margin-top: -18px; }

.footer .btn {
  margin-top: 0;
  font-size: 14px;
  padding: 10px 30px 8px 30px;
  font-weight: 500; }

.footer .copyright {
  background: black;
  display: -webkit-flex;
  display: flex;
  margin-top: 40px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px; }
  .footer .copyright p {
    color: white;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 0;
    padding-top: 22px;
    padding-bottom: 22px;
    padding-left: 15px;
    padding-right: 15px; }
  .footer .copyright a {
    color: white;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .info {
    margin-left: unset !important;
    margin-top: 20px; }
  .navigacija {
    margin-top: 20px; }
  .kontakt {
    margin-top: 20px; }
  .copyright {
    display: unset !important; }
    .copyright p {
      padding: 0px !important;
      padding-top: 30px !important; }
      .copyright p:last-child {
        margin-bottom: 30px !important; }
  .formula {
    height: 80px !important; } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .info {
    margin-left: unset !important; } }

.aboutUs {
  color: #222222; }
  .aboutUs .aboutContent {
    padding-top: 36px; }
  .aboutUs h1 {
    font-size: 45px;
    font-weight: 700;
    line-height: 54px; }
  .aboutUs p {
    font-size: 18px;
    padding-right: 40px;
    line-height: 24px;
    margin-bottom: 0px; }
  .aboutUs .paragraf {
    font-weight: 700;
    padding-top: 16px;
    margin-bottom: 12px; }
  .aboutUs .textDivider {
    margin-top: 48px;
    margin-bottom: 100px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .aboutUs .aboutContent h1 {
    font-size: 32px;
    line-height: 44px; }
  .aboutUs .aboutContent p {
    padding-right: 0px; }
  .aboutUs .aboutContent .textDivider {
    margin-top: 20px;
    margin-bottom: 40px; }
  .aboutUs .aboutContent img {
    margin-top: 20px; } }

.partnerWrapper .partner {
  padding-bottom: 88px; }
  .partnerWrapper .partner .partnerBox {
    height: 130px;
    width: 100%;
    border: 1px solid #707070;
    margin-top: 40px; }
    .partnerWrapper .partner .partnerBox img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.partnerWrapper .bottomText {
  width: 100%;
  text-align: center; }
  .partnerWrapper .bottomText h2 {
    font-size: 65px;
    margin-top: 90px;
    font-weight: 400; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .partnerWrapper .partner {
    padding-bottom: 40px; }
  .bottomText h2 {
    margin-top: 40px !important;
    font-size: 36px !important; } }

.contactForm .alert {
  margin-top: 16px; }

.contactForm .container {
  padding-top: 70px;
  padding-bottom: 70px; }

.contactForm .btn {
  background: #E50014;
  outline: none;
  border: none;
  font-size: 18px;
  line-height: 18px;
  color: white;
  padding-top: 12px;
  padding-bottom: 10px;
  padding-right: 23px;
  padding-left: 23px;
  border-radius: 6px;
  margin-top: 30px; }
  .contactForm .btn img {
    margin-left: 15px;
    left: unset;
    top: -2px; }

.contactForm h6 {
  color: #2F3234;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  margin-bottom: 22px; }

.contactForm input {
  border-radius: 22px;
  height: 44px !important; }
  .contactForm input::-webkit-input-placeholder {
    font-size: 14px;
    color: #232628;
    line-height: 16px;
    padding-left: 8px; }
  .contactForm input::-ms-input-placeholder {
    font-size: 14px;
    color: #232628;
    line-height: 16px;
    padding-left: 8px; }
  .contactForm input::placeholder {
    font-size: 14px;
    color: #232628;
    line-height: 16px;
    padding-left: 8px; }

.contactForm textarea {
  border-radius: 26px;
  height: 200px !important; }
  .contactForm textarea::-webkit-input-placeholder {
    font-size: 14px;
    color: #232628;
    line-height: 16px;
    padding-top: 14px; }
  .contactForm textarea::-ms-input-placeholder {
    font-size: 14px;
    color: #232628;
    line-height: 16px;
    padding-top: 14px; }
  .contactForm textarea::placeholder {
    font-size: 14px;
    color: #232628;
    line-height: 16px;
    padding-top: 14px; }

.contactForm label {
  display: block;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 14px;
  color: #9F9F9F;
  margin-top: 10px;
  margin-bottom: 30px; }

.contactForm .required {
  border: 2px solid #E50014;
  width: 100%;
  padding: 10px; }

.contactForm .contact-card {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  margin-top: 40px;
  background: white;
  border-radius: 20px;
  padding: 55px 30px 55px 40px; }
  .contactForm .contact-card h2 {
    font-size: 22px;
    line-height: 26px;
    color: #222222; }

.contactForm p {
  font-size: 16px;
  margin-bottom: 0;
  line-height: 18px;
  color: #222222; }

.contactForm .mid-text p {
  font-size: 18px;
  font-weight: 700;
  color: #222222;
  line-height: 20px;
  margin-top: 42px; }

.contactForm .firstParagraf {
  padding-top: 54px;
  font-size: 16px;
  color: #222222;
  line-height: 20px; }
  .contactForm .firstParagraf img {
    margin-right: 20px; }

.contactForm .secoundParagraf {
  padding-top: 54px;
  font-size: 16px;
  color: #222222;
  line-height: 20px;
  margin-left: 38px; }

.contactForm .thirdParagraf {
  font-size: 16px;
  color: #222222;
  line-height: 20px; }
  .contactForm .thirdParagraf img {
    margin-right: 27px;
    margin-top: -22px; }

.contactForm .fourthParagraf {
  padding-top: 54px;
  font-size: 16px;
  color: #222222;
  line-height: 20px;
  padding-bottom: 12px; }
  .contactForm .fourthParagraf img {
    margin-right: 20px; }

@media screen and (max-width: 1199px) and (min-width: 992px) {
  .fourthParagraf,
  .secoundParagraf,
  .thirdParagraf,
  .fourthParagraf {
    padding-top: 30px !important; } }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .contactForm .container {
    padding-top: 40px;
    padding-bottom: 60px; }
  .contactForm h6 {
    display: none; } }

.login {
  margin-top: -50px; }
  .login .container {
    padding-bottom: 100px; }
  .login h2 {
    margin-top: 90px;
    font-size: 32px;
    font-weight: 700;
    line-height: 20px;
    color: #222222; }
  .login h4 {
    font-size: 22px;
    line-height: 20px;
    color: #222222;
    margin-bottom: 0; }
  .login .btn {
    color: white;
    background: #222222;
    border: 0;
    border-radius: 23px;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    padding: 10px 37px;
    text-transform: uppercase;
    margin-top: 30px; }
  .login .loginForm {
    background: white;
    padding: 36px 36px;
    margin-top: 30px;
    padding-bottom: 54px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2); }
    .login .loginForm h6 {
      font-size: 22px;
      line-height: 20px;
      color: #222222; }
  .login Form {
    margin-top: 20px; }
    .login Form input {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
      height: 50px; }
    .login Form a {
      color: #222222;
      text-decoration: underline; }
    .login Form .btn {
      color: white;
      background: #E50014;
      border: 0;
      border-radius: 23px;
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
      padding: 10px 31px;
      text-transform: uppercase;
      margin-top: 30px; }
  .login hr {
    color: #C8C8C8;
    padding-right: 95px;
    margin-top: 40px;
    margin-bottom: 34px; }
  .login .required {
    border: 2px solid #E50014;
    width: 100%;
    padding: 10px; }
  .login label {
    display: block;
    margin-top: 22px; }
  .login h6 {
    margin-bottom: 0; }
  .login .loginForm .input-wrap {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse; }
  .login .loginForm form {
    margin-top: 0; }
    .login .loginForm form .nav-link {
      text-decoration: underline;
      padding: 0px;
      padding-top: 14px; }
  .login .loginForm .btn {
    display: block; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .login {
    padding-top: 50px; }
    .login .container {
      padding-bottom: 60px; }
    .login h2 {
      display: none; }
    .login .loginForm h6 {
      font-size: 19px; } }

.forgotPassword {
  margin-top: -50px; }
  .forgotPassword .container {
    padding-bottom: 100px; }
  .forgotPassword h2 {
    margin-top: 90px;
    font-size: 32px;
    font-weight: 700;
    line-height: 20px;
    color: #222222; }
  .forgotPassword h4 {
    font-size: 22px;
    line-height: 20px;
    color: #222222;
    margin-bottom: 0; }
  .forgotPassword .btn {
    color: white;
    background: #222222;
    border: 0;
    border-radius: 23px;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    padding: 10px 37px;
    text-transform: uppercase;
    margin-top: 30px; }
  .forgotPassword .loginForm {
    background: white;
    padding: 36px 36px;
    margin-top: 30px;
    padding-bottom: 54px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2); }
    .forgotPassword .loginForm h6 {
      font-size: 22px;
      line-height: 20px;
      color: #222222; }
  .forgotPassword Form {
    margin-top: 20px; }
    .forgotPassword Form input {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
      height: 50px; }
    .forgotPassword Form a {
      color: #222222;
      text-decoration: underline; }
    .forgotPassword Form .btn {
      color: white;
      background: #E50014;
      border: 0;
      border-radius: 23px;
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
      padding: 10px 31px;
      text-transform: uppercase;
      margin-top: 30px; }
  .forgotPassword hr {
    color: #C8C8C8;
    padding-right: 95px;
    margin-top: 40px;
    margin-bottom: 34px; }
  .forgotPassword .loginForm .btn {
    margin-top: 30px; }
  .forgotPassword .loginForm p {
    font-size: 16px;
    color: #222222;
    line-height: 20px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .login {
    padding-top: 50px; }
    .login .container {
      padding-bottom: 60px; }
    .login h2 {
      display: none; }
    .login .loginForm h6 {
      font-size: 19px; }
  .forgotPassword {
    padding-top: 50px; }
    .forgotPassword h2 {
      display: none; }
    .forgotPassword .container {
      padding-bottom: 60px; } }

.resetPassword {
  margin-top: -50px; }
  .resetPassword .container {
    padding-bottom: 100px; }
  .resetPassword h2 {
    margin-top: 90px;
    font-size: 32px;
    font-weight: 700;
    line-height: 20px;
    color: #222222; }
  .resetPassword h4 {
    font-size: 22px;
    line-height: 20px;
    color: #222222;
    margin-bottom: 0; }
  .resetPassword .btn {
    color: white;
    background: #222222;
    border: 0;
    border-radius: 23px;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    padding: 10px 37px;
    text-transform: uppercase;
    margin-top: 30px; }
  .resetPassword .loginForm {
    background: white;
    padding: 36px 36px;
    margin-top: 30px;
    padding-bottom: 54px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2); }
    .resetPassword .loginForm h6 {
      font-size: 22px;
      line-height: 20px;
      color: #222222; }
  .resetPassword Form {
    margin-top: 20px; }
    .resetPassword Form input {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
      height: 50px; }
    .resetPassword Form a {
      color: #222222;
      text-decoration: underline; }
    .resetPassword Form .btn {
      color: white;
      background: #E50014;
      border: 0;
      border-radius: 23px;
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
      padding: 10px 31px;
      text-transform: uppercase;
      margin-top: 30px; }
  .resetPassword hr {
    color: #C8C8C8;
    padding-right: 95px;
    margin-top: 40px;
    margin-bottom: 34px; }
  .resetPassword .loginForm .btn {
    margin-top: 30px; }
  .resetPassword .loginForm p {
    font-size: 16px;
    color: #222222;
    line-height: 20px; }
  .resetPassword Form {
    margin-top: 0; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .login {
    padding-top: 50px; }
    .login .container {
      padding-bottom: 60px; }
    .login h2 {
      display: none; }
    .login .loginForm h6 {
      font-size: 19px; }
  .resetPassword {
    padding-top: 50px; }
    .resetPassword h2 {
      display: none; }
    .resetPassword .container {
      padding-bottom: 60px; } }

.register {
  margin-top: -50px; }
  .register .container {
    padding-bottom: 100px; }
  .register h2 {
    margin-top: 90px;
    font-size: 32px;
    font-weight: 700;
    line-height: 20px;
    color: #222222; }
  .register h4 {
    font-size: 22px;
    line-height: 20px;
    color: #222222;
    margin-bottom: 0; }
  .register .btn {
    color: white;
    background: #222222;
    border: 0;
    border-radius: 23px;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    padding: 10px 37px;
    text-transform: uppercase;
    margin-top: 30px; }
  .register .loginForm {
    background: white;
    padding: 36px 36px;
    margin-top: 30px;
    padding-bottom: 54px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2); }
    .register .loginForm h6 {
      font-size: 22px;
      line-height: 20px;
      color: #222222; }
  .register Form {
    margin-top: 20px; }
    .register Form input {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
      height: 50px; }
    .register Form a {
      color: #222222;
      text-decoration: underline; }
    .register Form .btn {
      color: white;
      background: #E50014;
      border: 0;
      border-radius: 23px;
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
      padding: 10px 31px;
      text-transform: uppercase;
      margin-top: 30px; }
  .register hr {
    color: #C8C8C8;
    padding-right: 95px;
    margin-top: 40px;
    margin-bottom: 34px; }
  .register .newParagraf {
    margin: 0 !important;
    margin-top: 20px !important;
    margin-bottom: 8px !important; }
  .register .newWrapper .loginForm {
    padding: 6px 36px; }
  .register .wrapInput {
    margin-top: 12px; }
    .register .wrapInput .input-wrap {
      all: unset !important; }
  .register .loginForm .btn {
    margin-top: 30px; }
  .register .loginForm p {
    font-size: 16px;
    color: #222222;
    line-height: 20px;
    margin-left: 15px; }
  .register p {
    margin-top: 22px; }
    .register p span {
      color: #E50014; }
  .register Form label span {
    color: #E50014; }
  .register .required {
    border: 2px solid #E50014;
    width: 100%;
    padding: 10px; }
  .register label {
    display: block;
    margin-top: 22px; }
  .register h6 {
    margin-bottom: 0; }
  .register .loginForm .input-wrap {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse; }
  .register .loginForm form {
    margin-top: 0; }
    .register .loginForm form .nav-link {
      text-decoration: underline;
      padding: 0px;
      padding-top: 14px; }
  .register .loginForm .btn {
    display: block; }
  .register .input-wrap {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse; }
    .register .input-wrap .checkbox {
      border: 1px solid #CCCCCC;
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin-right: 8px;
      border-radius: 4px;
      float: left; }
    .register .input-wrap .checked {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
      .register .input-wrap .checked:after {
        content: '';
        display: block;
        border-right: 3px solid #E50014;
        border-bottom: 3px solid #E50014;
        width: 6px;
        height: 12px;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .login {
    padding-top: 50px; }
    .login .container {
      padding-bottom: 60px; }
    .login h2 {
      display: none; }
    .login .loginForm h6 {
      font-size: 19px; }
  .register {
    padding-top: 50px; }
    .register h2 {
      display: none; }
    .register .container {
      padding-bottom: 60px; } }

.categoryDetail {
  margin-top: -50px; }
  .categoryDetail .article-image {
    /* width */
    /* Track */
    /* Handle */
    /* Handle on hover */ }
    .categoryDetail .article-image ::-webkit-scrollbar {
      width: 5px;
      height: 5px; }
    .categoryDetail .article-image ::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.2);
      border-radius: rem-calc(5); }
    .categoryDetail .article-image ::-webkit-scrollbar-thumb {
      background: #E50014;
      border-radius: rem-calc(5);
      cursor: pointer; }
    .categoryDetail .article-image ::-webkit-scrollbar-thumb:hover {
      background: #E50014;
      cursor: pointer; }
    .categoryDetail .article-image .preview {
      width: 100%;
      object-fit: contain;
      object-position: center;
      height: 350px; }
    .categoryDetail .article-image .images {
      width: 100%;
      padding-top: 20px;
      overflow: auto;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      cursor: -webkit-grab;
      cursor: grab;
      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      /* Konqueror HTML */
      -moz-user-select: none;
      /* Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none;
      /* Non-prefixed version, currently*/ }
      @media only screen and (max-width: 768px) {
        .categoryDetail .article-image .images {
          margin-bottom: 12px; } }
      .categoryDetail .article-image .images .image {
        display: inline-block;
        margin-right: 10px;
        width: 120px;
        height: 100px;
        position: relative; }
        .categoryDetail .article-image .images .image::after {
          content: " ";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.7); }
        .categoryDetail .article-image .images .image > img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
          .categoryDetail .article-image .images .image > img:last-child {
            margin-right: 0; }
      .categoryDetail .article-image .images .active {
        position: relative; }
        .categoryDetail .article-image .images .active::after {
          content: " ";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: transparent; }
  .categoryDetail h2 {
    margin-top: 90px;
    font-size: 32px;
    font-weight: 700;
    line-height: 20px;
    color: #222222; }
  .categoryDetail .content {
    background: white;
    margin-top: 30px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    padding: 25px 35px 25px 35px;
    display: -webkit-flex;
    display: flex; }
    .categoryDetail .content .artikal {
      max-width: 288px; }
    .categoryDetail .content h1 {
      font-size: 28px;
      line-height: 36px;
      color: #222222;
      margin-top: 36px;
      margin-bottom: 26px; }
      .categoryDetail .content h1 span {
        color: #E50014;
        font-size: 26px; }
    .categoryDetail .content hr {
      background: #E50014; }
    .categoryDetail .content .price {
      font-size: 36px;
      line-height: 36px;
      color: #E50014;
      margin-top: 26px; }
    .categoryDetail .content .original-price {
      font-size: 36px;
      line-height: 36px;
      color: #cacaca;
      text-decoration: line-through;
      margin-top: 26px; }
    .categoryDetail .content .spacer {
      margin-top: 40px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
    .categoryDetail .content .spacer2 {
      margin-top: 40px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
      .categoryDetail .content .spacer2 a {
        color: #222222; }
      .categoryDetail .content .spacer2 .socialMedia {
        margin-left: 20px; }
      .categoryDetail .content .spacer2 .heart {
        margin-right: 10px; }
      .categoryDetail .content .spacer2 p {
        margin-bottom: 0px; }
    .categoryDetail .content .btn1 {
      background: white;
      color: #222222;
      border: 1px solid #E50014;
      padding: 11px 46px 11px 18px;
      font-size: 17px;
      line-height: 24px;
      font-weight: 500; }
      .categoryDetail .content .btn1 svg {
        fill: none;
        stroke: #E50014;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 1px;
        margin-right: 12px; }
    .categoryDetail .content .btn2 {
      background: #E50014;
      color: #FFFFFF;
      border: 1px solid #E50014;
      padding: 12px 39px 12px 39px;
      font-size: 17px;
      line-height: 24px;
      font-weight: 500; }
  .categoryDetail .content2 {
    background: white;
    padding: 35px 30px 35px 30px;
    margin-top: 26px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    margin-bottom: 50px; }
    .categoryDetail .content2 h6 {
      font-size: 24px;
      color: #222222;
      line-height: 20px;
      margin-top: 0; }
    .categoryDetail .content2 p {
      margin-top: 16px;
      font-size: 18px;
      line-height: 21px;
      color: #222222; }

@media screen and (max-width: 1199px) and (min-width: 992px) {
  .categoryDetail .content {
    padding: 25px 15px 25px 0px; }
    .categoryDetail .content .artikal {
      max-width: 250px; } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .categoryDetail .content {
    padding: 25px 15px 25px 0px; }
    .categoryDetail .content .artikal {
      max-width: 250px; } }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .categoryDetail .content {
    display: block;
    padding: 0px;
    padding-bottom: 30px; }
    .categoryDetail .content .spacer {
      -webkit-justify-content: space-between;
              justify-content: space-between;
      display: -webkit-flex;
      display: flex; }
    .categoryDetail .content .spacer2 {
      margin-top: 30px; }
      .categoryDetail .content .spacer2 p {
        display: block; }
    .categoryDetail .content h1 {
      margin-top: 0; }
    .categoryDetail .content .btn1 {
      margin-left: unset;
      padding: 8px 15px 8px 15px; }
    .categoryDetail .content .btn2 {
      margin-left: unset;
      padding: 9px 22px 9px 22px; }
    .categoryDetail .content .artikal {
      max-width: unset; } }

.profile {
  margin-top: -50px; }
  .profile .find-client-wrap {
    margin-bottom: 32px;
    position: relative; }
    .profile .find-client-wrap ul {
      position: absolute;
      top: 70px;
      background-color: #FFFFFF;
      width: 100%;
      padding: 32px 16px;
      list-style: none;
      z-index: 9999;
      border: 1px solid #D1D1D1; }
      .profile .find-client-wrap ul > li {
        padding: 8px 16px; }
        .profile .find-client-wrap ul > li:hover {
          background-color: #F5F5F5;
          cursor: pointer; }
  .profile .delete-client {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 100%; }
    .profile .delete-client .isvg {
      cursor: pointer; }
  .profile .profileTop {
    display: -webkit-flex;
    display: flex;
    margin-top: 90px;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    .profile .profileTop h2 {
      font-size: 32px;
      font-weight: 700;
      line-height: 20px;
      color: #222222; }
    .profile .profileTop .btn {
      background: #E50014;
      border: 0;
      font-size: 18px;
      line-height: 22px;
      font-weight: 500;
      padding: 14px 22px;
      outline: none;
      margin-top: -18px; }
      .profile .profileTop .btn svg {
        margin-right: 22px; }
  .profile .content {
    background: white;
    padding: 30px;
    margin-top: 20px;
    padding-bottom: 10px; }
    .profile .content .boxContent {
      border: 2px solid #E50014;
      border-radius: 6px;
      -webkit-justify-content: center;
              justify-content: center;
      height: 160px;
      margin-right: 16px;
      display: block;
      margin-bottom: 20px; }
      .profile .content .boxContent svg {
        margin-top: 24px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 50px; }
      .profile .content .boxContent p {
        text-align: center;
        font-size: 18px;
        line-height: 21px;
        margin-top: 18px; }
    .profile .content a {
      color: #222222;
      text-decoration: none; }

.newsSpacer .container {
  padding-top: 70px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .profile .profileTop h2 {
    font-size: 22px; }
  .profile .profileTop .btn {
    font-size: 16px; }
    .profile .profileTop .btn svg {
      margin-right: 10px; }
  .profile .content p {
    display: block;
    text-align: center !important; }
  .profile .content .boxContent {
    margin-right: 0; }
  .newsSpacer .container {
    padding-top: 50px; } }

.orders {
  background: white;
  margin-top: 20px;
  padding: 30px;
  padding-bottom: 10px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2); }
  .orders table thead {
    background: #E50014;
    border: 0;
    color: white;
    height: 20px;
    outline: none;
    border: 1px solid #E50014; }
    .orders table thead tr th {
      padding: 6px 16px;
      border: 0;
      font-weight: 400; }
  .orders table tbody {
    border: 1px solid #C8C8C8; }
    .orders table tbody td,
    .orders table tbody th {
      font-weight: 400;
      border: 1px solid #C8C8C8 !important;
      height: 60px; }
      .orders table tbody td svg,
      .orders table tbody th svg {
        margin-right: 12px; }
  .orders .payed {
    color: green;
    font-weight: 800; }
  .orders .not-payed {
    color: #E50014;
    font-weight: 800; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .orders {
    padding: 0px !important; }
    .orders table thead {
      font-size: 14px; }
    .orders table tbody {
      font-size: 14px;
      text-align: center; }
      .orders table tbody td,
      .orders table tbody th {
        padding: 8px;
        height: unset;
        vertical-align: middle; }
        .orders table tbody td svg,
        .orders table tbody th svg {
          margin-right: unset;
          display: block;
          margin-left: auto;
          margin-right: auto; } }

.delivery {
  background: white;
  padding: 30px;
  margin-top: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2); }
  .delivery p {
    margin: 0;
    font-size: 18px;
    line-height: 24px;
    color: #222222; }
  .delivery input {
    margin-right: 10px; }
  .delivery .deliveryBox {
    background: #E50014;
    color: white;
    padding: 8px 16px; }
    .delivery .deliveryBox h6 {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 0; }
  .delivery .text {
    padding: 18px 20px;
    border: 1px solid #CCCCCC; }
  .delivery .btn1 {
    color: white;
    background: #E50014;
    border: 0;
    border-radius: 23px;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    padding: 10px 31px;
    text-transform: uppercase;
    margin-top: 30px; }
  .delivery .btn2 {
    color: white;
    background: #222222;
    border: 0;
    border-radius: 23px;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    padding: 10px 16px;
    text-transform: uppercase;
    margin-top: 30px;
    margin-left: 20px; }

.delivery-no-shadow {
  box-shadow: unset; }

.radio-button-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer; }

.radio-button {
  border: 2px solid #D1D1D1;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 8px;
  border-radius: 50%;
  float: left; }

.radio-button-checked {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .radio-button-checked::after {
    content: " ";
    display: block;
    width: 10px;
    height: 10px;
    background-color: #E50014;
    border-radius: 50%; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .delivery {
    padding: 0px;
    background: unset;
    box-shadow: none; }
    .delivery .text {
      background: white; }
    .delivery .btn1 {
      margin: auto;
      display: block;
      margin-top: 30px;
      font-size: 16px; }
    .delivery .btn2 {
      display: block;
      margin: auto;
      margin-top: 30px;
      font-size: 16px; }
    .delivery .radio-button-wrap {
      margin-top: 30px; } }

.newsletterPage {
  background: white;
  padding: 30px;
  margin-top: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  padding-bottom: 56px; }
  .newsletterPage p {
    margin: 0;
    font-size: 18px; }
    .newsletterPage p:first-child {
      margin-bottom: 18px; }
  .newsletterPage .paragraf {
    margin-left: 40px;
    padding-top: 12px; }
  .newsletterPage .spacer {
    margin-top: 50px; }
  .newsletterPage .btn1 {
    color: white;
    background: #E50014;
    border: 0;
    border-radius: 23px;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    padding: 10px 31px;
    text-transform: uppercase;
    margin-top: 0px; }
  .newsletterPage .btn2 {
    color: white;
    background: #222222;
    border: 0;
    border-radius: 23px;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    padding: 10px 33px;
    text-transform: uppercase;
    margin-top: 0px; }
  .newsletterPage .buttons {
    margin-top: 86px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .newsletterPage {
    padding-bottom: 40px; }
    .newsletterPage .spacer {
      margin-top: 30px; }
    .newsletterPage .buttons {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      margin-top: 0px; }
      .newsletterPage .buttons .btn1,
      .newsletterPage .buttons .btn2 {
        margin: unset;
        font-size: 14px;
        margin-top: 40px; } }

.favoriteArticles {
  margin-top: -50px; }
  .favoriteArticles .watermark {
    position: absolute;
    z-index: 2;
    top: 12px;
    left: 8px; }
  .favoriteArticles h2 {
    margin-top: 90px; }
  .favoriteArticles .articleBox .btn {
    bottom: 10px; }
  .favoriteArticles .articleBox .closeIcon {
    position: absolute;
    right: 10px;
    z-index: 5;
    display: block;
    top: 10px;
    cursor: pointer; }
  .favoriteArticles .wrapper {
    box-shadow: 0px 2px 4px 0px #dbdbdb; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .favoriteArticles {
    padding-top: 50px; }
    .favoriteArticles h2 {
      display: none; }
    .favoriteArticles .articleBox h2 {
      display: unset; } }

.cart-wrap {
  margin-left: 20px; }
  @media only screen and (max-width: 767px) {
    .cart-wrap {
      margin-left: 0px; } }
  .cart-wrap .table {
    border: 1px solid #D1D1D1; }
    @media only screen and (max-width: 991px) {
      .cart-wrap .table {
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-bottom: 16px; } }
  .cart-wrap .secoundSvg {
    margin-top: -5px;
    margin-left: 4px; }
  .cart-wrap .cart-progress {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    list-style: none;
    padding: 0;
    width: 81%;
    margin-top: 40px; }
    @media only screen and (max-width: 767px) {
      .cart-wrap .cart-progress {
        width: 100%; } }
    .cart-wrap .cart-progress > li {
      position: relative;
      width: 140px;
      text-align: center; }
      .cart-wrap .cart-progress > li::before {
        content: " ";
        position: absolute;
        display: block;
        height: 6px;
        width: 98px;
        background-color: #CCCCCC;
        border-radius: 13px;
        left: 100%;
        margin-left: -28px;
        top: 21px; }
        @media only screen and (max-width: 767px) {
          .cart-wrap .cart-progress > li::before {
            width: 30px;
            margin-left: -15px; } }
        @media only screen and (min-width: 768px) and (max-width: 1112px) {
          .cart-wrap .cart-progress > li::before {
            width: 70px; } }
      .cart-wrap .cart-progress > li::after {
        content: " ";
        position: absolute;
        height: 6px;
        width: 0;
        background-color: #E50014;
        border-radius: 13px;
        left: 100%;
        margin-left: -28px;
        top: 21px;
        /* For Safari 3.1 to 6.0 */
        transition: width 0.5s; }
        @media only screen and (max-width: 767px) {
          .cart-wrap .cart-progress > li::after {
            margin-left: -15px; } }
      .cart-wrap .cart-progress > li:last-child::before {
        display: none; }
      .cart-wrap .cart-progress > li:last-child::after {
        display: none; }
      .cart-wrap .cart-progress > li .icon {
        margin: 0 auto;
        margin-bottom: 16px;
        border: 4px solid #CCCCCC;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center; }
        .cart-wrap .cart-progress > li .icon svg {
          fill: #E50014;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 2px; }
      .cart-wrap .cart-progress > li p {
        max-width: 140px;
        text-align: center;
        margin-bottom: 0;
        font-size: 18px;
        line-height: 21px; }
        .cart-wrap .cart-progress > li p:nth-child(2) {
          font-size: 18px;
          font-weight: 600; }
          @media only screen and (max-width: 767px) {
            .cart-wrap .cart-progress > li p:nth-child(2) {
              font-size: 11px; } }
        @media only screen and (max-width: 767px) {
          .cart-wrap .cart-progress > li p:nth-child(3) {
            display: none; } }
      .cart-wrap .cart-progress > li:nth-child(1) svg {
        fill: #E50014; }
    .cart-wrap .cart-progress .active::after {
      width: 49px; }
      @media only screen and (min-width: 768px) and (max-width: 1112px) {
        .cart-wrap .cart-progress .active::after {
          width: 35px; } }
      @media only screen and (max-width: 767px) {
        .cart-wrap .cart-progress .active::after {
          width: 15px; } }
    .cart-wrap .cart-progress .active .icon {
      background-color: #E50014;
      border: 0; }
    .cart-wrap .cart-progress .active:nth-child(1) svg {
      stroke: #FFFFFF; }
    .cart-wrap .cart-progress .active:nth-child(4) svg {
      fill: #FFFFFF;
      stroke: unset; }
    .cart-wrap .cart-progress .done::after {
      width: 98px;
      display: block; }
      @media only screen and (min-width: 768px) and (max-width: 1112px) {
        .cart-wrap .cart-progress .done::after {
          width: 70px; } }
      @media only screen and (max-width: 767px) {
        .cart-wrap .cart-progress .done::after {
          width: 30px; } }
    .cart-wrap .cart-progress .done .icon {
      background-color: #E50014;
      border: 0; }
    .cart-wrap .cart-progress .done:nth-child(1) svg {
      stroke: #FFFFFF; }
    .cart-wrap .cart-progress .done:nth-child(4) svg {
      fill: #FFFFFF;
      stroke: unset; }
  @media only screen and (max-width: 991px) {
    .cart-wrap .button-group {
      padding: 0 !important; } }
  .cart-wrap .back {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: #222222;
    font-size: 16px;
    height: 40px; }
    .cart-wrap .back::before {
      content: " ";
      display: block;
      border-bottom: 2px solid #E50014;
      border-right: 2px solid #E50014;
      -webkit-transform: rotate(135deg);
              transform: rotate(135deg);
      width: 8px;
      height: 8px;
      margin-right: 6px; }
  .cart-wrap .checkout-button {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
    .cart-wrap .checkout-button .button {
      margin-top: auto;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
      .cart-wrap .checkout-button .button::after {
        content: " ";
        display: block;
        border-bottom: 2px solid #FFFFFF;
        border-right: 2px solid #FFFFFF;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
        width: 8px;
        height: 8px;
        float: right;
        margin-left: 8px; }
  .cart-wrap .checkout-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    margin-bottom: 20px; }
    .cart-wrap .checkout-buttons span {
      padding: 0 16px; }
    .cart-wrap .checkout-buttons .button {
      margin-top: auto;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
      .cart-wrap .checkout-buttons .button::after {
        content: " ";
        display: block;
        border-bottom: 2px solid #FFFFFF;
        border-right: 2px solid #FFFFFF;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
        width: 8px;
        height: 8px;
        float: right;
        margin-left: 8px; }
  .cart-wrap .notes-input .input-wrap {
    margin-bottom: 0; }
    .cart-wrap .notes-input .input-wrap input[type=text] {
      height: 36px; }
  .cart-wrap .coupon-input .input-wrap {
    margin-bottom: 0;
    margin-bottom: 32px; }
    .cart-wrap .coupon-input .input-wrap input[type=text] {
      height: 36px;
      width: 120px; }
  .cart-wrap .cart-table {
    border: 1px solid #D1D1D1;
    border-bottom: 0; }
    .cart-wrap .cart-table .header {
      background-color: #E50014;
      height: 34px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .cart-wrap .cart-table .header > div {
        color: #FFFFFF;
        font-weight: 600; }
      .cart-wrap .cart-table .header .number {
        text-align: right; }
    .cart-wrap .cart-table .article-info {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      font-size: 14px; }
      .cart-wrap .cart-table .article-info > div {
        padding: 12px 8px;
        border-right: 1px solid #D1D1D1;
        border-bottom: 1px solid #D1D1D1;
        height: 60px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center; }
        .cart-wrap .cart-table .article-info > div:last-child {
          border-right: 0; }
      .cart-wrap .cart-table .article-info .select-field {
        width: 100%; }
        @media only screen and (max-width: 991px) {
          .cart-wrap .cart-table .article-info .select-field {
            font-size: 10px; } }
      @media only screen and (max-width: 991px) {
        .cart-wrap .cart-table .article-info {
          font-size: 12px; } }
      .cart-wrap .cart-table .article-info input[type=text] {
        height: 36px;
        width: 50px;
        border-radius: 6px;
        border: 1px solid #D1D1D1;
        padding: 0 16px;
        margin-right: 8px; }
        @media only screen and (max-width: 991px) {
          .cart-wrap .cart-table .article-info input[type=text] {
            width: 30px;
            padding: 0 8px; } }
      .cart-wrap .cart-table .article-info .number {
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
        padding-right: 12px; }
      .cart-wrap .cart-table .article-info .delete {
        -webkit-justify-content: center;
                justify-content: center; }
      .cart-wrap .cart-table .article-info label {
        margin-bottom: 0; }
        @media only screen and (max-width: 991px) {
          .cart-wrap .cart-table .article-info label {
            display: none; } }
      .cart-wrap .cart-table .article-info img {
        width: 44px;
        height: 33px;
        margin-right: 12px; }
    .cart-wrap .cart-table .payment-info {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      font-size: 14px; }
      .cart-wrap .cart-table .payment-info > div {
        padding: 12px 8px;
        border-right: 1px solid #D1D1D1;
        border-bottom: 1px solid #D1D1D1;
        height: 500px; }
        .cart-wrap .cart-table .payment-info > div:last-child {
          border-right: 0; }
      .cart-wrap .cart-table .payment-info .select-field {
        width: 100%; }
        @media only screen and (max-width: 991px) {
          .cart-wrap .cart-table .payment-info .select-field {
            font-size: 10px; } }
      @media only screen and (max-width: 991px) {
        .cart-wrap .cart-table .payment-info {
          font-size: 12px; } }
      .cart-wrap .cart-table .payment-info .number {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
        padding-right: 12px; }
      .cart-wrap .cart-table .payment-info .delete {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center; }
      .cart-wrap .cart-table .payment-info label {
        margin-bottom: 0; }
      .cart-wrap .cart-table .payment-info img {
        width: 44px;
        height: 33px;
        margin-right: 12px; }

.continueShopping {
  background: white;
  padding: 25px 38px;
  margin-top: 25px;
  margin-bottom: 70px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2); }
  .continueShopping h6 {
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #222222; }
  .continueShopping p {
    margin: 0;
    font-size: 17px;
    line-height: 24px;
    color: #222222;
    margin-top: 30px; }
  .continueShopping a {
    color: #E50014;
    text-decoration: none;
    font-weight: 700; }
  @media only screen and (max-width: 767px) {
    .continueShopping {
      margin-bottom: 40px;
      margin-top: 40px;
      padding: 25px 15px; }
      .continueShopping p {
        margin-top: 20px; } }

.shoppingTable {
  background: white;
  margin-top: 20px;
  padding: 30px;
  padding-bottom: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2); }
  .shoppingTable .input-wrap input[type=text] {
    margin-bottom: 16px; }
  .shoppingTable .uplatnica {
    width: 100%; }
  .shoppingTable .deliveryBox-container {
    margin-bottom: 16px; }
  .shoppingTable .billing-methods-row {
    height: 100px !important; }
    .shoppingTable .billing-methods-row > div {
      height: 100% !important; }
  .shoppingTable .billing-methods {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    padding: 16px; }
    .shoppingTable .billing-methods > p {
      margin-bottom: 16px !important;
      cursor: pointer; }
  .shoppingTable .select-field {
    border: 1px solid #D1D1D1;
    background-color: #FFFFFF;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 14px; }
    .shoppingTable .select-field a {
      color: #222222; }
    .shoppingTable .select-field .dropdown-menu {
      width: 100%;
      max-height: 300px;
      overflow: auto; }
    .shoppingTable .select-field .dropdown-toggle::after {
      display: none; }
    .shoppingTable .select-field .dropdown-toggle::before {
      content: " ";
      display: block;
      border-top: 2px solid #222222;
      border-right: 2px solid #222222;
      width: 10px;
      height: 10px;
      -webkit-transform: rotate(135deg);
              transform: rotate(135deg);
      float: right;
      margin-top: 6px; }
  .shoppingTable .deliveryWrapper {
    margin-bottom: 40px; }
    @media only screen and (max-width: 767px) {
      .shoppingTable .deliveryWrapper .delivery {
        padding: 0px; }
        .shoppingTable .deliveryWrapper .delivery .txt1 {
          margin-bottom: 30px; } }
  .shoppingTable .deliveryForm {
    margin-top: 40px; }
    @media only screen and (max-width: 767px) {
      .shoppingTable .deliveryForm {
        padding: 15px;
        margin-top: 10px; } }
    .shoppingTable .deliveryForm input {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      height: 50px !important; }
    .shoppingTable .deliveryForm h6 {
      font-size: 22px;
      margin-bottom: 20px; }
    .shoppingTable .deliveryForm p span,
    .shoppingTable .deliveryForm label span {
      color: #E50014; }
    .shoppingTable .deliveryForm .btn {
      margin-top: 36px;
      padding: 10px 73px; }
  .shoppingTable .delivery {
    padding: 0px;
    box-shadow: none; }
    @media only screen and (max-width: 767px) {
      .shoppingTable .delivery {
        padding-left: 15px;
        padding-right: 15px; } }
    .shoppingTable .delivery .paragraph {
      font-size: 16px;
      font-weight: 400;
      padding-top: 20px; }
    @media only screen and (max-width: 767px) {
      .shoppingTable .delivery .centriraj {
        display: block;
        margin: auto; } }
  .shoppingTable .input-wrap2 {
    padding-top: 30px; }
    .shoppingTable .input-wrap2 .checkbox {
      border: 1px solid #CCCCCC;
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin-right: 8px;
      border-radius: 4px;
      float: left; }
    .shoppingTable .input-wrap2 .checked {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
      .shoppingTable .input-wrap2 .checked:after {
        content: '';
        display: block;
        border-right: 3px solid #E50014;
        border-bottom: 3px solid #E50014;
        width: 6px;
        height: 12px;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); }
  .shoppingTable .btn {
    color: white;
    background: #E50014;
    border: 0;
    border-radius: 23px;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    padding: 10px 31px;
    text-transform: uppercase;
    margin-top: 50px; }
    @media only screen and (max-width: 767px) {
      .shoppingTable .btn {
        margin-top: 20px;
        margin-bottom: 30px;
        font-size: 14px;
        padding: 10px 18px; } }
    @media only screen and (min-width: 991px) {
      .shoppingTable .btn {
        margin-right: 15px; } }
    .shoppingTable .btn svg {
      position: relative;
      left: 12px;
      top: -2px; }
  .shoppingTable .btn2 {
    color: white;
    background: #333333;
    border: 0;
    border-radius: 23px;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    padding: 10px 31px;
    text-transform: uppercase;
    margin-top: 50px; }
    @media only screen and (max-width: 767px) {
      .shoppingTable .btn2 {
        margin-top: 20px;
        margin-bottom: 30px;
        font-size: 14px;
        padding: 10px 18px; } }
    @media only screen and (min-width: 991px) {
      .shoppingTable .btn2 {
        margin-right: 15px; } }
  @media only screen and (max-width: 767px) {
    .shoppingTable .btn3 {
      padding: 10px 111px;
      display: block;
      margin: auto;
      margin-bottom: 20px; } }
  .shoppingTable .whiteContainer {
    background: white;
    padding: 0px; }
    .shoppingTable .whiteContainer p {
      margin-bottom: 0; }
    .shoppingTable .whiteContainer a {
      color: #E50014;
      text-decoration: none;
      font-weight: 700; }
    .shoppingTable .whiteContainer .link2 {
      color: #222222;
      font-weight: 400;
      margin-top: 2px; }
    .shoppingTable .whiteContainer svg {
      margin-top: -3px; }
    .shoppingTable .whiteContainer .backToHome {
      margin-top: 40px;
      margin-bottom: 20px; }
    @media only screen and (max-width: 767px) {
      .shoppingTable .whiteContainer {
        padding: 15px; }
        .shoppingTable .whiteContainer .backToHome {
          margin-top: 20px; } }
  .shoppingTable .cart-table {
    border: 1px solid #D1D1D1;
    border-bottom: 0;
    margin-bottom: 24px; }
    .shoppingTable .cart-table .header {
      background-color: #E50014;
      height: 34px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      margin-right: -16px;
      margin-left: -16px; }
      .shoppingTable .cart-table .header > div {
        color: #FFFFFF;
        font-weight: 600;
        padding-left: 1px; }
        .shoppingTable .cart-table .header > div:first-child {
          padding-left: 20px; }
      .shoppingTable .cart-table .header .number {
        text-align: right; }
    .shoppingTable .cart-table .article-info {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      background: white;
      font-size: 14px; }
      .shoppingTable .cart-table .article-info .inputBox {
        display: block !important;
        margin: auto !important;
        text-align: center !important; }
        .shoppingTable .cart-table .article-info .inputBox input {
          width: 60px;
          border: 1px solid #C8C8C8;
          border-radius: 6px;
          height: 36px;
          padding: 6px !important;
          margin-right: 0 !important; }
          .shoppingTable .cart-table .article-info .inputBox input p {
            margin-bottom: 0;
            margin-top: 6px;
            text-align: center; }
      .shoppingTable .cart-table .article-info > div {
        border-right: 1px solid #D1D1D1;
        border-bottom: 1px solid #D1D1D1;
        height: 60px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center; }
        .shoppingTable .cart-table .article-info > div p {
          margin-bottom: 0;
          font-size: 16px; }
          .shoppingTable .cart-table .article-info > div p span {
            font-weight: 700; }
        .shoppingTable .cart-table .article-info > div:last-child {
          border-right: 0; }
      .shoppingTable .cart-table .article-info .select-field {
        width: 100%; }
        @media only screen and (max-width: 991px) {
          .shoppingTable .cart-table .article-info .select-field {
            font-size: 10px; } }
      @media only screen and (max-width: 991px) {
        .shoppingTable .cart-table .article-info {
          font-size: 12px; } }
      .shoppingTable .cart-table .article-info input[type=text] {
        height: 36px;
        width: 50px;
        border-radius: 6px;
        border: 1px solid #D1D1D1;
        padding: 0 16px;
        margin-right: 8px;
        text-align: center; }
        @media only screen and (max-width: 991px) {
          .shoppingTable .cart-table .article-info input[type=text] {
            width: 30px;
            padding: 0 8px; } }
      .shoppingTable .cart-table .article-info .number {
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
        padding-right: 12px; }
      .shoppingTable .cart-table .article-info .delete {
        -webkit-justify-content: center;
                justify-content: center; }
        .shoppingTable .cart-table .article-info .delete svg {
          cursor: pointer !important; }
      .shoppingTable .cart-table .article-info label {
        margin-bottom: 0; }
        @media only screen and (max-width: 991px) {
          .shoppingTable .cart-table .article-info label {
            display: none; } }
      .shoppingTable .cart-table .article-info img {
        width: 44px;
        height: 33px;
        margin-right: 12px; }
    .shoppingTable .cart-table .payment-info {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      font-size: 14px; }
      .shoppingTable .cart-table .payment-info > div {
        padding: 12px 8px;
        border-right: 1px solid #D1D1D1;
        border-bottom: 1px solid #D1D1D1;
        height: 500px; }
        .shoppingTable .cart-table .payment-info > div:last-child {
          border-right: 0; }
      .shoppingTable .cart-table .payment-info .select-field {
        width: 100%; }
        @media only screen and (max-width: 991px) {
          .shoppingTable .cart-table .payment-info .select-field {
            font-size: 10px; } }
      @media only screen and (max-width: 991px) {
        .shoppingTable .cart-table .payment-info {
          font-size: 12px; } }
      .shoppingTable .cart-table .payment-info .number {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
        padding-right: 12px; }
      .shoppingTable .cart-table .payment-info .delete {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center; }
      .shoppingTable .cart-table .payment-info label {
        margin-bottom: 0; }
      .shoppingTable .cart-table .payment-info img {
        width: 44px;
        height: 33px;
        margin-right: 12px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .shoppingTable {
    padding: 0px; }
    .shoppingTable .cart-table .header > div:first-child {
      padding-left: 6px !important; }
    .shoppingTable .cart-table .header > div:nth-child(2) {
      padding-left: 10px !important; }
    .shoppingTable .cart-table .article-info > div:last-child {
      padding: 0px !important; }
    .shoppingTable .cart-table .article-info > div p {
      font-size: 12px !important; } }

.homeGalery .prva {
  height: 100% !important;
  object-fit: cover; }

.homeGalery img {
  object-fit: cover; }

.homeGalery > div {
  padding-top: 40px; }

.homeGalery h4 {
  color: #222222;
  font-size: 32px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 24px; }

.homeGalery .link {
  color: #E50014;
  text-decoration: underline;
  position: relative; }
  .homeGalery .link:before {
    content: " " !important;
    display: block !important;
    border-left: 2.5px solid #E50014 !important;
    border-top: 2.5px solid #E50014 !important;
    width: 12px !important;
    height: 12px !important;
    -webkit-transform: rotate(130deg) !important;
            transform: rotate(130deg) !important;
    position: absolute;
    left: -20px;
    top: 6px; }

.galleryNaslov .container {
  padding-bottom: 40px; }

.galleryNaslov .naslov p {
  padding: 0px 100px;
  margin-top: 16px; }

.pictures {
  margin-top: 20px;
  position: relative; }
  .pictures img {
    display: block;
    margin: auto;
    position: relative;
    top: 35%; }
  .pictures .img-fluid {
    height: 236px; }

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background: #E50014; }

.pictures:hover .overlay {
  opacity: 0.8; }
  .pictures:hover .overlay img {
    margin: auto;
    display: block;
    position: relative;
    top: 35%; }

.gallery-wrapper .container {
  padding-bottom: 80px; }

.lightbox {
  background-color: #333333;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20; }
  .lightbox .container {
    background: #333333; }
  .lightbox img {
    height: 100vh;
    object-fit: contain;
    margin-top: 40px; }
  .lightbox .fa-times {
    color: white;
    font-size: 42px !important;
    position: absolute;
    top: 60px;
    right: 70px;
    z-index: 2; }
    .lightbox .fa-times:hover {
      cursor: pointer; }
  .lightbox .fa-angle-left {
    color: white;
    font-size: 50px;
    position: fixed;
    top: 48%;
    left: 40px; }
    .lightbox .fa-angle-left:hover {
      cursor: pointer; }
  .lightbox .fa-angle-right {
    color: white;
    font-size: 50px;
    position: fixed;
    top: 48%;
    right: 40px; }
    .lightbox .fa-angle-right:hover {
      cursor: pointer; }

.homeGalery .container {
  padding-bottom: 30px; }

.slikee img {
  height: 200px;
  width: 100%; }

.rowDivider {
  margin-top: 30px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .galleryNaslov .naslov p {
    display: none; } }

@media screen and (max-width: 1199px) and (min-width: 1024px) {
  .prva {
    height: 430px !important; } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .slikee img {
    height: 152px; } }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .slikee img {
    margin-top: 20px;
    height: 240px; }
  .rowDivider {
    margin-top: unset; }
  .prva {
    height: 240px !important;
    width: 100%; }
  .homeGalery .link {
    margin-left: 15px;
    margin-bottom: 30px; } }

.wrapperPreparation {
  color: #fff;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  display: -webkit-flex;
  display: flex; }
  .wrapperPreparation .bgc {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
    z-index: -1;
    position: fixed;
    object-fit: cover; }
  .wrapperPreparation .preparation {
    text-align: center;
    margin: auto; }
    .wrapperPreparation .preparation .logo {
      margin-top: 85px; }
    .wrapperPreparation .preparation h1 {
      color: #fff;
      font-size: 60px;
      font-weight: 700;
      margin-top: 28px;
      margin-bottom: 60px; }
    .wrapperPreparation .preparation .login-form {
      margin-top: 60px;
      text-align: start;
      text-align: left; }
      .wrapperPreparation .preparation .login-form h3 {
        font-size: 32px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 26px; }
      .wrapperPreparation .preparation .login-form h4 {
        margin-bottom: 21px;
        font-size: 22px;
        text-align: center; }
      .wrapperPreparation .preparation .login-form .form-group label {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        text-align: start;
        margin-left: 20px;
        margin-top: 23px; }
      .wrapperPreparation .preparation .login-form .form-group input {
        margin: 0 auto 0 auto;
        height: 50px;
        width: 500px;
        background-color: #fff;
        border: 1px solid #C8C8C8;
        border-radius: 6px; }
        .wrapperPreparation .preparation .login-form .form-group input:focus {
          outline: none;
          -webkit-box-shadow: none !important; }
      .wrapperPreparation .preparation .login-form p {
        margin-left: 20px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        text-align: start !important; }
      .wrapperPreparation .preparation .login-form .btn {
        text-align: center;
        background-color: #E50014;
        border: none;
        border-radius: 23px;
        color: #fff;
        font-size: 18px;
        line-height: 20px;
        font-weight: 700;
        padding: 0;
        height: 40px;
        width: 150px; }
        .wrapperPreparation .preparation .login-form .btn:focus {
          outline: none;
          -webkit-box-shadow: none !important;
          background-color: #E50014 !important;
          color: #fff; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .wrapperPreparation .preparation {
    text-align: center; }
    .wrapperPreparation .preparation .logo {
      margin-top: 20px; }
    .wrapperPreparation .preparation h1 {
      color: #fff;
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 20px; }
    .wrapperPreparation .preparation .login-form {
      margin-top: 25px;
      text-align: start;
      text-align: left; }
      .wrapperPreparation .preparation .login-form h3 {
        font-size: 17px;
        font-weight: 700;
        text-align: center; }
      .wrapperPreparation .preparation .login-form h4 {
        font-size: 11px;
        text-align: center; }
      .wrapperPreparation .preparation .login-form .form-group label {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        text-align: start;
        margin-left: 5px; }
      .wrapperPreparation .preparation .login-form .form-group input {
        height: 40px;
        width: 300px; }
      .wrapperPreparation .preparation .login-form p {
        margin-left: 5px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        text-align: start !important; }
      .wrapperPreparation .preparation .login-form .btn {
        margin-top: 15px; } }

